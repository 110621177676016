import { gql, useMutation } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";

import { useAppStateDispatch } from "../../AppStateContext";
import { formatDateTime } from "../../helpers";

const ADMIN_CREATE_API_KEY = gql`
  mutation AdminCreateApiKey($orgId: ID!) {
    adminCreateApiKey(orgId: $orgId) {
      id
      token
      createdAt
    }
  }
`;

const OrgApiKeys = ({ organization }) => {
  const dispatch = useAppStateDispatch();
  const [showApiTokens, setShowApiTokens] = useState(false);

  const [doCreateApiKeyMutation, { loading }] = useMutation(ADMIN_CREATE_API_KEY);

  const createNewApiKey = () => {
    const confirmed = window.confirm("Are you sure you want to create a new API key for this org?");
    if (!confirmed) {
      return;
    }
    doCreateApiKeyMutation({
      variables: { orgId: organization.id },
      onCompleted: data => {
        dispatch({
          type: "admin-organizations-add-api-key",
          organizationId: organization.id,
          apiKey: data.adminCreateApiKey,
        });
        toast("API key added.", { type: "success" });
      },
    });
  };

  const apiKeysDisplay =
    organization.apiKeys.length > 0 ? (
      <>
        <Typography variant="h3">API keys:</Typography>
        <Button color="secondary" onClick={() => setShowApiTokens(!showApiTokens)} size="small">
          {showApiTokens ? "Hide" : "Show"} tokens
        </Button>
        <ul>
          {organization.apiKeys.map((apiKey, i) => (
            <li key={i}>
              API key created at {formatDateTime(apiKey.createdAt)}
              {showApiTokens ? (
                <>
                  : <code>{apiKey.token}</code>
                </>
              ) : null}
            </li>
          ))}
        </ul>
      </>
    ) : null;
  const createApiKeyDisplay = (
    <Button disabled={loading} onClick={createNewApiKey}>
      Create new API key
    </Button>
  );

  return (
    <>
      {apiKeysDisplay}
      {createApiKeyDisplay}
    </>
  );
};

export default OrgApiKeys;
