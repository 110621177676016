import { gql, useMutation } from "@apollo/client";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { Box, Typography, Grid, Button, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppStateDispatch } from "../../AppStateContext";
import { CONTENT_GROUP_TYPES } from "../../constants";
import { formatDateTime } from "../../helpers";
import useGetContentGroupFromParam from "../../hooks/useGetContentGroupFromParam";
import { TypographyWithSpacing } from "../../styles";
import CampaignsUsedIn from "../outreach_campaign/CampaignsUsedIn";
import ActionGrid from "../shared/ActionGrid";
import ContentCannotBeChangedTooltipWrapper from "../shared/ContentCannotBeChangedTooltipWrapper";
import Loading from "../shared/Loading";
import TextLabel from "../shared/TextLabel";

import ContentGroupsHeader from "./ContentGroupsHeader";
import VariationPreview from "./VariationPreview";

const ARCHIVE_CONTENT_GROUP = gql`
  mutation ($contentGroupTypedId: String!) {
    archiveContentGroup(contentGroupTypedId: $contentGroupTypedId)
  }
`;

const ContentGroupDetail = () => {
  const contentGroup = useGetContentGroupFromParam();
  const [doArchiveContentGroup, { loading: isArchiveContentGroupLoading }] =
    useMutation(ARCHIVE_CONTENT_GROUP);
  const navigate = useNavigate();
  const dispatch = useAppStateDispatch();

  const archiveContentGroup = () => {
    const confirmed = window.confirm("Are you sure you want to delete this content?");
    if (!confirmed) {
      return;
    }
    doArchiveContentGroup({
      variables: { contentGroupTypedId: contentGroup.typedId },
      onCompleted: () => {
        dispatch({ type: "org-delete-content-group", contentGroupTypedId: contentGroup.typedId });
        toast("Content deleted.", { type: "success" });
        navigate("/content");
      },
    });
  };

  if (isArchiveContentGroupLoading) {
    return <Loading />;
  }

  if (!contentGroup) {
    return "Content not found.";
  }

  const hasNoVariations = contentGroup.variations.length === 0;

  const campaignsUsedIn = <CampaignsUsedIn audienceOrContentGroup={contentGroup} />;

  const contentGroupActions = (
    <ActionGrid>
      <Grid item>
        <ContentCannotBeChangedTooltipWrapper shouldShow={contentGroup.isLocked}>
          <Button
            component={Link}
            disabled={contentGroup.isLocked}
            startIcon={CONTENT_GROUP_TYPES[contentGroup.type].icon}
            to={`/content/variation/new/${contentGroup.typedId}/`}
          >
            Create new variation
          </Button>
        </ContentCannotBeChangedTooltipWrapper>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            hasNoVariations ? (
              <Typography variant="subtitle2">
                Add one or more variations to evaluate this content.
              </Typography>
            ) : null
          }
        >
          <span>
            <Button
              component={Link}
              disabled={hasNoVariations}
              startIcon={<AutoFixHighIcon />}
              to={`/evaluations/new/content/${contentGroup.typedId}`}
            >
              Evaluate content
            </Button>
          </span>
        </Tooltip>
      </Grid>
    </ActionGrid>
  );

  return (
    <>
      <Box maxWidth="md">
        <ContentGroupsHeader />
        <Grid container alignItems="center" columnSpacing={1} rowSpacing={0}>
          <Grid item xs style={{ display: "flex", gap: "0.5rem" }}>
            <Typography margin="0" variant="h2">
              {contentGroup.name}
            </Typography>
          </Grid>
          <Grid item justifyContent="flex-end">
            {!contentGroup.isLocked ? (
              <Button onClick={archiveContentGroup} size="small" variant="text">
                Delete
              </Button>
            ) : null}
          </Grid>
        </Grid>
        {contentGroupActions}
        <TypographyWithSpacing>
          <TextLabel>Type: </TextLabel> {CONTENT_GROUP_TYPES[contentGroup.type].name}
        </TypographyWithSpacing>
        <TypographyWithSpacing>
          <TextLabel>Number of variations: </TextLabel> {contentGroup.variations.length}
        </TypographyWithSpacing>
        <TypographyWithSpacing>
          <TextLabel>Created: </TextLabel> {formatDateTime(contentGroup.createdAt)}
        </TypographyWithSpacing>
        {campaignsUsedIn ? <TypographyWithSpacing>{campaignsUsedIn}</TypographyWithSpacing> : null}

        <Grid container spacing={2}>
          {contentGroup.variations &&
            contentGroup.variations.map(v => (
              <Grid item key={v.typedId} lg={3} sx={{ padding: "1.5rem 0" }} xs={4}>
                <VariationPreview includeName variation={v} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
};

export default ContentGroupDetail;
