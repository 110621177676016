import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

import SelectAudience from "./SelectAudience";
import UploadNewAudienceModal from "./UploadNewAudienceModal";

const SelectAudienceOrUpload = ({
  audienceFilter,
  flowType,
  selectedAudience,
  setSelectedAudience,
  setUploadedAudienceId,
  subtitle,
  tooltipText,
  validAudiences,
}) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [audienceWasUploaded, setAudienceWasUploaded] = useState(false);

  const handleUploadedAudienceIsReady = uploadedAudience => {
    setAudienceWasUploaded(true);
    setSelectedAudience(uploadedAudience);
    setUploadedAudienceId(uploadedAudience.id);
    setIsUploadModalOpen(false);
  };

  let selectAudience = (
    <SelectAudience
      {...{ additionalAudiencesFilter: audienceFilter, selectedAudience, setSelectedAudience }}
      includeRequiresPayment={true}
      isDisabled={validAudiences.length === 0}
      label=""
      placeholder="Select an audience"
      subtitle={subtitle}
    />
  );
  if (tooltipText) {
    selectAudience = (
      <Grid container alignItems="left">
        <Grid item xs={11}>
          {selectAudience}
        </Grid>
        <Grid item sx={{ ml: 2, mt: 1 }}>
          <Tooltip title={tooltipText}>
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
    );
  }

  const onClickUpload = () => {
    setSelectedAudience(null);
    setIsUploadModalOpen(true);
  };

  return (
    <>
      <Typography variant="h5">Select an audience or upload a new CSV</Typography>
      {selectAudience}
      <Box sx={{ marginTop: "1rem" }}>
        <Button color="secondary" onClick={onClickUpload}>
          Upload a {audienceWasUploaded ? "different" : ""} CSV
        </Button>
      </Box>
      {isUploadModalOpen ? (
        <UploadNewAudienceModal
          flowType={flowType}
          handleUploadedAudienceIsReady={handleUploadedAudienceIsReady}
          setIsOpen={setIsUploadModalOpen}
        />
      ) : null}
    </>
  );
};

export default SelectAudienceOrUpload;
