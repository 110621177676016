import { gql, useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppStateDispatch } from "../../AppStateContext";
import { CORE_AUDIENCE_FIELDS } from "../../fragments";

const PROMOTE_AUDIENCE = gql`
  ${CORE_AUDIENCE_FIELDS}
  mutation ($pk: ID!, $name: String!) {
    promoteAudience(pk: $pk, name: $name) {
      ...CoreAudienceFields
    }
  }
`;

const PromoteAudience = ({
  audience,
  buttonWidth,
  audienceName,
  validateAudienceName,
  additionalOnClickHandler,
  isDisabled,
}) => {
  const [doPromoteAudienceMutation, { loading: promoteAudienceLoading }] =
    useMutation(PROMOTE_AUDIENCE);
  const dispatch = useAppStateDispatch();
  const navigate = useNavigate();

  const promoteAudience = () => {
    if (additionalOnClickHandler) {
      additionalOnClickHandler();
    }
    if (!validateAudienceName()) {
      return;
    }
    doPromoteAudienceMutation({
      variables: {
        pk: audience.id,
        name: audienceName,
      },
      onCompleted: data => {
        if (data.promoteAudience.id) {
          dispatch({
            type: "org-add-or-update-audience",
            organizationId: data.promoteAudience.organization.id,
            audience: data.promoteAudience,
          });
          toast("Audience created.", { type: "success" });
          navigate(`/audiences/${data.promoteAudience.id}/export`);
        }
      },
    });
  };

  return (
    <Button
      disabled={promoteAudienceLoading || isDisabled}
      onClick={promoteAudience}
      sx={{ width: buttonWidth }}
    >
      {promoteAudienceLoading ? "Loading..." : "Create & export audience"}
    </Button>
  );
};

export default PromoteAudience;
