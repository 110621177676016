import { Box } from "@mui/material";

import { CONTENT_GROUP_TYPES } from "../../constants";
import { countAndNoun, formatDate } from "../../helpers";
import { OneLineTypography } from "../../styles";
import CardItem from "../shared/CardItem";

const ContentGroupListItem = ({ contentGroup, isInline = false }) => {
  const numVariations = contentGroup.variations && contentGroup.variations.length;
  return (
    <CardItem isInline={isInline} linkTo={`/content/group/${contentGroup.typedId}`}>
      <Box>
        <OneLineTypography
          sx={{ marginBottom: "0.25rem", textDecoration: isInline ? "underline" : "" }}
          title={contentGroup.name}
          variant="h3"
        >
          {contentGroup.name}
        </OneLineTypography>
        <OneLineTypography title={CONTENT_GROUP_TYPES[contentGroup.type].name}>
          {CONTENT_GROUP_TYPES[contentGroup.type].name}
        </OneLineTypography>
        <OneLineTypography>{formatDate(contentGroup.createdAt)}</OneLineTypography>
        <OneLineTypography>{countAndNoun(numVariations, "variation")}</OneLineTypography>
      </Box>
    </CardItem>
  );
};

export default ContentGroupListItem;
