import { gql, useMutation } from "@apollo/client";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandIcon from "@mui/icons-material/Expand";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { compact, sortBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, Routes, Route } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppStateDispatch } from "../../AppStateContext";
import {
  AUDIENCE_RELATIONSHIP_NAMES_WITH_REVERSE_NAMES,
  AUDIENCE_RELATIONSHIP_NAME_DISPLAY,
} from "../../constants";
import { CORE_AUDIENCE_FIELDS } from "../../fragments";
import {
  countAndNoun,
  csvForAudience,
  formatDateTime,
  isAudienceCurrentActiveNotPreviewNotArchived,
  userFriendlyNumber,
} from "../../helpers";
import useGetActiveOrg from "../../hooks/useGetActiveOrg";
import useGetAudienceFromParam from "../../hooks/useGetAudienceFromParam";
import useGetCurrentVersionOfAudienceFilteredFrom from "../../hooks/useGetCurrentVersionOfAudienceFilteredFrom";
import useMaintainCurrentAudienceId from "../../hooks/useMaintainCurrentAudienceId";
import useValidAudiencesForLookalikeCopy from "../../hooks/useValidAudiencesForLookalikeCopy";
import { OneLineTypography } from "../../styles";
import CampaignsUsedIn from "../outreach_campaign/CampaignsUsedIn";
import ActionGrid from "../shared/ActionGrid";
import InfoBox from "../shared/InfoBox";
import Loading from "../shared/Loading";
import SharedModal from "../shared/SharedModal";
import TextLabel from "../shared/TextLabel";

import AudienceCsvExportModalContent from "./AudienceCsvExportModalContent";
import AudienceDemographics from "./AudienceDemographics";
import AudienceErrorInfoBox from "./AudienceErrorInfoBox";
import AudienceFacebookExportButton from "./AudienceFacebookExportButton";
import AudienceHeatMap from "./AudienceHeatMap";
import AudiencesHeader from "./AudiencesHeader";
import CsvMatchSummaryModal from "./CsvMatchSummaryModal";
import FiltersSummaries from "./FiltersSummaries";

const TOGGLE_AUDIENCE_ARCHIVED = gql`
  ${CORE_AUDIENCE_FIELDS}
  mutation ($pk: ID!, $isArchived: Boolean!) {
    toggleAudienceArchived(pk: $pk, isArchived: $isArchived) {
      ...CoreAudienceFields
    }
  }
`;

const REGENERATE_AUDIENCE = gql`
  ${CORE_AUDIENCE_FIELDS}
  mutation ($pk: ID!, $regenerateParent: Boolean!, $regenerateChildren: Boolean!) {
    regenerateAudience(
      pk: $pk
      regenerateParent: $regenerateParent
      regenerateChildren: $regenerateChildren
    ) {
      ...CoreAudienceFields
    }
  }
`;

const AudienceDetail = () => {
  const validAudiencesForLookalikeCopy = useValidAudiencesForLookalikeCopy();

  const [isRelationshipsModalOpen, setIsRelationshipsModalOpen] = useState(false);
  const [isCsvMatchSummaryModalOpen, setIsCsvMatchSummaryModalOpen] = useState(false);

  const dispatch = useAppStateDispatch();
  const navigate = useNavigate();
  const { organization } = useGetActiveOrg();
  const [doRegenerateMutation, { loading: regenerateLoading }] = useMutation(REGENERATE_AUDIENCE);
  const [doToggleArchivedMutation, { loading: toggleArchivedLoading }] =
    useMutation(TOGGLE_AUDIENCE_ARCHIVED);

  const { audiences } = organization;
  const audience = useGetAudienceFromParam();

  // Hide modal if audience ID changes (i.e. when you navigate to a different audience).
  useEffect(() => {
    setIsRelationshipsModalOpen(false);
  }, [audience]);

  useMaintainCurrentAudienceId(audience && audience.id);

  const setIsExportModalOpen = isOpen => {
    if (isOpen) {
      navigate(`/audiences/${audience.id}/export`);
    } else {
      navigate(`/audiences/${audience.id}`);
    }
  };

  const handleRegenerateAudience = ({ regenerateParent = false, regenerateChildren = false }) => {
    doRegenerateMutation({
      variables: { pk: audience.id, regenerateParent, regenerateChildren },
      onCompleted: data => {
        if (data.regenerateAudience.length > 0) {
          dispatch({
            type: "org-add-or-update-audiences",
            organizationId: data.regenerateAudience[0].organization.id,
            audiences: data.regenerateAudience,
          });
          // @todo-ui-later might be better to only navigate to the new audience once it's active?
          navigate(`/audiences/${data.regenerateAudience[0].id}`);
        }
      },
    });
  };

  const audienceLookalikeCreatedFrom = useMemo(
    () =>
      audience && audience.audienceLookalikeCreatedFrom
        ? audiences.find(a => a.id === audience.audienceLookalikeCreatedFrom.id)
        : null,
    [audience, audiences],
  );

  const getCurrentVersionOfAudienceFilteredFrom = useGetCurrentVersionOfAudienceFilteredFrom();

  if (!audience || audience.isPreview) {
    return "Audience not found.";
  }

  const currentVersionOfAudienceFilteredFrom = getCurrentVersionOfAudienceFilteredFrom(audience);

  const handleToggleArchived = isArchived => {
    if (isArchived) {
      const confirmed = window.confirm(
        "Are you sure you want to archive this audience? (You can always unarchive it later.)",
      );
      if (!confirmed) {
        return;
      }
    }
    doToggleArchivedMutation({
      variables: { pk: audience.id, isArchived },
      onCompleted: data => {
        if (data.toggleAudienceArchived.id) {
          dispatch({
            type: "org-add-or-update-audience",
            organizationId: data.toggleAudienceArchived.organization.id,
            audience: data.toggleAudienceArchived,
          });
          let actionText = "unarchived";
          if (isArchived) {
            actionText = "archived";
            navigate("/audiences");
          }
          toast(`Audience ${actionText}.`, { type: "success" });
        }
      },
      onError: e => {
        if (e.graphQLErrors && e.graphQLErrors[0].extensions.class === "AudienceAlreadyArchived") {
          navigate("/audiences");
        }
      },
    });
  };

  let audienceNotActiveDisplay, audienceNotCurrentDisplay;
  if (audience.status === "ERROR") {
    audienceNotActiveDisplay = <AudienceErrorInfoBox audience={audience} />;
  } else if (audience.status === "PENDING") {
    const pendingCopy = audience.audienceRegeneratedFrom
      ? "Regenerating audience..."
      : "Creating audience...";
    audienceNotActiveDisplay = (
      <InfoBox type="warning">
        <div style={{ fontStyle: "italic" }}>{pendingCopy}</div>
        <Loading center={false} />
      </InfoBox>
    );
  } else if (!audience.isCurrentVersion) {
    // Only show this if not pending or error.
    if (audience.currentVersion) {
      audienceNotCurrentDisplay = (
        <InfoBox type="warning">
          This is an older version of your audience.{" "}
          <Link to={`/audiences/${audience.currentVersion.id}`}>See the current version</Link>.
        </InfoBox>
      );
    }
  }

  let audienceIsArchivedDisplay;
  if (audience.isArchived) {
    audienceIsArchivedDisplay = (
      <InfoBox type="warning">
        This audience is archived. You can{" "}
        <Link onClick={() => handleToggleArchived(false)}>unarchive it</Link>.
      </InfoBox>
    );
  }

  const reverseRelationshipNames = Object.values(AUDIENCE_RELATIONSHIP_NAMES_WITH_REVERSE_NAMES);
  let numAudienceRelationships = 0;
  // Only include audiences that are current active not preview not archived for the "derived to"
  // audience relationships.
  const relationshipsToDisplay = compact(
    reverseRelationshipNames.map(reverseRelationshipName => {
      const relationships = audience[reverseRelationshipName];
      const relatedAudiences = relationships
        .map(relationshipId => audiences.find(a => a.id === relationshipId))
        .filter(isAudienceCurrentActiveNotPreviewNotArchived);
      if (!relatedAudiences || relatedAudiences.length === 0) {
        return null;
      }
      const relationshipsDisplay = sortBy(relatedAudiences, "name").map(aud => {
        numAudienceRelationships += 1;
        return (
          <li key={aud.id}>
            <Link to={`/audiences/${aud.id}`}>{aud.name}</Link>{" "}
          </li>
        );
      });
      return (
        <div key={reverseRelationshipName}>
          {AUDIENCE_RELATIONSHIP_NAME_DISPLAY[reverseRelationshipName]}:{" "}
          <ul style={{ margin: "3px 0" }}>{relationshipsDisplay}</ul>
        </div>
      );
    }),
  );

  const isRegenerateDisabled = regenerateLoading || !!audience.pendingVersion;
  const audienceIsActive = audience.status === "ACTIVE";
  const isCurrentActiveNotPreviewNotArchived =
    isAudienceCurrentActiveNotPreviewNotArchived(audience);
  const isExpandAllowed =
    audience.canCreateLookalikeFrom ||
    (audience.audienceFilteredFrom && audience.audienceFilteredFrom.canCreateLookalikeFrom) ||
    (currentVersionOfAudienceFilteredFrom &&
      currentVersionOfAudienceFilteredFrom.canCreateLookalikeFrom);
  const isActionDisabled = !isCurrentActiveNotPreviewNotArchived;

  let regenerateTooltipText;
  if (audience.pendingVersion) {
    regenerateTooltipText = "A new version of this audience will be available soon.";
  } else if (audience.audienceLookalikeCreatedFrom) {
    regenerateTooltipText = "Regenerate a new version of this lookalike.";
  } else if (audience.uploadedAudienceCsv) {
    regenerateTooltipText = "Match the same CSV to an updated version of the voter file.";
  } else if (audience.audienceFilteredFrom) {
    regenerateTooltipText = "Apply the same filters to an updated version of voter data.";
  } else if (audience.isFilteredFromNational) {
    regenerateTooltipText = "Apply the same filters to an updated version of the voter file.";
  }

  let exportButton, facebookButton;
  if (audienceIsActive) {
    exportButton = (
      <Button onClick={() => setIsExportModalOpen(true)} startIcon={<DownloadIcon />}>
        Export to CSV
      </Button>
    );
    facebookButton = <AudienceFacebookExportButton audience={audience} />;
  }

  let audienceActions = (
    <>
      <ActionGrid>
        <Grid item md={2.5}>
          <Typography sx={{ margin: 0 }} variant="h3">
            Take action
          </Typography>
        </Grid>
        <Grid item>{exportButton}</Grid>
        <Grid item>{facebookButton}</Grid>
      </ActionGrid>
      <ActionGrid>
        <Grid item md={2.5}>
          <Typography sx={{ margin: 0 }} variant="h3">
            Modify
          </Typography>
        </Grid>
        <Grid item>
          {/* @todo-ui-later regnerate with parent/children */}
          {/* {audience.audienceFilteredFrom && audience.audienceFilteredFrom.id ? (
            <Button
              disabled={isRegenerateDisabled}
              onClick={() => handleRegenerateAudience({ regenerateParent: true })}
            >
              {regenerateLoading ? (
                <Loading center={false} />
              ) : (
                "Regenerate with parent"
              )}
            </Button>
          ) : null}
          {audience.canNarrowFrom ? (
            <Button
              disabled={isRegenerateDisabled}
              onClick={() => handleRegenerateAudience({ regenerateChildren: true })}
            >
              {regenerateLoading ? (
                <Loading center={false} />
              ) : (
                "Regenerate with children"
              )}
            </Button>
          ) : null} */}
          <Tooltip
            title={
              isActionDisabled ? null : (
                <Typography variant="subtitle2">{regenerateTooltipText}</Typography>
              )
            }
          >
            {/* The span is needed to avoid an error when the tooltip is present but the button
            is disabled. See https://mui.com/material-ui/react-tooltip/#disabled-elements */}
            <span>
              <Button
                disabled={isRegenerateDisabled || isActionDisabled}
                onClick={handleRegenerateAudience}
                startIcon={<RefreshIcon />}
                variant="outlined"
              >
                Regenerate
              </Button>
            </span>
          </Tooltip>
        </Grid>
        {audience.canNarrowFrom || audience.canCopyAndEditFiltersFrom ? (
          <Grid item>
            <Button
              component={Link}
              disabled={isActionDisabled}
              startIcon={audience.canNarrowFrom ? <FilterAltOutlinedIcon /> : <ContentCopyIcon />}
              to={`/audiences/create/${audience.id}`}
              variant="outlined"
            >
              {audience.canNarrowFrom ? "Apply filters" : "Copy and edit filters"}
            </Button>
          </Grid>
        ) : null}
        <Grid item>
          <Tooltip
            title={
              !isActionDisabled && !isExpandAllowed ? (
                <Typography variant="subtitle2">{validAudiencesForLookalikeCopy}</Typography>
              ) : null
            }
          >
            <span>
              <Button
                component={Link}
                disabled={isActionDisabled || !isExpandAllowed}
                startIcon={<ExpandIcon />}
                to={`/audiences/expand/${audience.id}`}
                variant="outlined"
              >
                Expand
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </ActionGrid>
    </>
  );

  let sourceInfo;
  const csv = csvForAudience({ audience, organization });
  if (audienceLookalikeCreatedFrom) {
    sourceInfo = (
      <>
        Expanding{" "}
        <Link to={`/audiences/${audienceLookalikeCreatedFrom.id}`}>
          {audienceLookalikeCreatedFrom.name}
        </Link>
      </>
    );
  } else if (csv) {
    if (audience.uploadedAudienceCsvMatchMetadata) {
      sourceInfo = (
        <>
          CSV match (
          <Link onClick={() => setIsCsvMatchSummaryModalOpen(true)}>
            <span title={csv.name}>{csv.name}</span>
          </Link>
          )
        </>
      );
    } else {
      sourceInfo = <>CSV match {csv.name ? <span title={csv.name}>({csv.name})</span> : null}</>;
    }
  } else if (audience.audienceFilteredFrom) {
    sourceInfo = (
      <>
        Applying filters to{" "}
        {audience.isFilteredFromLookalike ? " lookalike audience " : " CSV-matched audience "}
        <Link to={`/audiences/${audience.audienceFilteredFrom.id}`}>
          {audience.audienceFilteredFrom.name}
        </Link>
      </>
    );
  } else if (audience.isFilteredFromNational) {
    sourceInfo = <>Filtering from national</>;
  }

  const campaignsUsedIn = <CampaignsUsedIn audienceOrContentGroup={audience} />;

  // @todo Improve Created/Via/By labels, but without repeating "Created" 3 times?
  const audienceInfo = (
    <Grid container alignItems="flex-start" sx={{ margin: "1rem 0" }}>
      {/* Left column */}
      <Grid container item rowSpacing={2} sx={{ paddingRight: "5px" }} xs={6}>
        <Grid item xs={12}>
          <TextLabel>Created: </TextLabel> {formatDateTime(audience.createdAt)}
        </Grid>
        <Grid item xs={12}>
          <OneLineTypography>
            <TextLabel>Via: </TextLabel> {sourceInfo}
          </OneLineTypography>
        </Grid>
        <Grid item xs={12}>
          <OneLineTypography>
            <TextLabel>By: </TextLabel> {audience.createdBy.firstName} {audience.createdBy.lastName}
          </OneLineTypography>
        </Grid>
        {campaignsUsedIn ? (
          <Grid item xs={12}>
            {campaignsUsedIn}
          </Grid>
        ) : null}
        {numAudienceRelationships > 0 ? (
          <Grid item xs={12}>
            <TextLabel>Audience relationships: </TextLabel>
            <Link onClick={() => setIsRelationshipsModalOpen(true)}>
              {countAndNoun(numAudienceRelationships, "audience")}
            </Link>
          </Grid>
        ) : null}
        {audience.filters ? (
          <Grid item xs={12}>
            <TextLabel>Filters: </TextLabel>
            <FiltersSummaries audience={audience} />
          </Grid>
        ) : null}
        {audience.audienceLookalikeCreatedFrom ? (
          <Grid item xs={12}>
            <TextLabel>Lookalikes requested: </TextLabel>
            {userFriendlyNumber(audience.numLookalikesRequested)}
            {audience.lookalikeIncludeOriginalAudience ? " (plus original audience)" : null}
          </Grid>
        ) : null}
      </Grid>

      {/* Right column */}
      {audienceIsActive ? (
        <Grid container item alignItems="flex-start" rowSpacing={2} xs={6}>
          <Grid item xs={12}>
            <TextLabel>Size: </TextLabel>
            {audience.metadata && userFriendlyNumber(audience.metadata.size)}
          </Grid>
          <Grid item xs={12}>
            <TextLabel>Phones: </TextLabel>
            {audience.metadata && userFriendlyNumber(audience.metadata.num_phones)}
          </Grid>
          <Grid item xs={12}>
            <TextLabel>Emails: </TextLabel>{" "}
            {audience.metadata && userFriendlyNumber(audience.metadata.num_emails)}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );

  const exportModal = (
    <SharedModal
      isOpen={true}
      setIsOpen={setIsExportModalOpen}
      title="Audience export"
      width={1000}
    >
      <AudienceCsvExportModalContent audience={audience} organization={organization} />
    </SharedModal>
  );

  return (
    <Box maxWidth="md">
      <AudiencesHeader showNewAudienceButton={false} />
      <Grid container alignItems="center" columnSpacing={1} rowSpacing={0}>
        <Grid item xs style={{ display: "flex", gap: "0.5rem" }}>
          <Typography sx={{ margin: 0 }} variant="h2">
            {audience.name}
          </Typography>
        </Grid>
        <Grid item justifyContent="flex-end">
          {isCurrentActiveNotPreviewNotArchived ? (
            <Button
              disabled={toggleArchivedLoading}
              onClick={() => handleToggleArchived(true)}
              size="small"
              variant="text"
            >
              Archive
            </Button>
          ) : null}
        </Grid>
      </Grid>

      {audienceIsArchivedDisplay}
      {audienceNotActiveDisplay}
      {audienceNotCurrentDisplay}
      {isAudienceCurrentActiveNotPreviewNotArchived(audience) ? audienceActions : null}

      {audienceInfo}

      {audience.status === "ACTIVE" ? (
        <>
          <AudienceDemographics audience={audience} />

          <Typography variant="h4">Location</Typography>
          <AudienceHeatMap audience={audience} />
        </>
      ) : null}

      <SharedModal
        isOpen={isRelationshipsModalOpen}
        setIsOpen={setIsRelationshipsModalOpen}
        title="Audience relationships"
      >
        <Box>{relationshipsToDisplay}</Box>
      </SharedModal>

      <CsvMatchSummaryModal
        {...{ audience, csv }}
        isOpen={isCsvMatchSummaryModalOpen}
        setIsOpen={setIsCsvMatchSummaryModalOpen}
      />

      <Routes>
        <Route element={exportModal} path="export" />
      </Routes>
    </Box>
  );
};

export default AudienceDetail;
