import { Grid, Box } from "@mui/material";

import useGetCurrentContentGroups from "../../hooks/useGetCurrentContentGroups";

import ContentGroupListItem from "./ContentGroupListItem";
import ContentGroupsHeader from "./ContentGroupsHeader";

const ContentGroupList = () => {
  const contentGroups = useGetCurrentContentGroups();

  const contentGroupList = contentGroups.map(cg => (
    <ContentGroupListItem contentGroup={cg} key={cg.typedId} />
  ));

  // @todo-content update copy once we enable the other content options.
  const contentGroupListUi =
    contentGroupList.length > 0 ? (
      <Grid container spacing={4}>
        {contentGroupList}
      </Grid>
    ) : (
      <Box>
        To create your first content, click the "New" button above and select "Digital ad" from the
        dropdown.
      </Box>
    );

  return (
    <>
      <ContentGroupsHeader alternateRightContent={null} />
      {contentGroupListUi}
    </>
  );
};

export default ContentGroupList;
