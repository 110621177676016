import { Box, Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import useGetCurrentAudiences from "../../hooks/useGetCurrentAudiences";
import XOutAdornment from "../shared/XOutAdornment";

import AudienceListItem from "./AudienceListItem";
import AudiencesHeader from "./AudiencesHeader";

const AudienceList = () => {
  const [shouldIncludeArchived, setShouldIncludeArchived] = useState(
    JSON.parse(localStorage.getItem("shouldIncludeArchived")),
  );
  const [audienceSearch, setAudienceSearch] = useState("");

  const getAudiencesParams = {
    includeArchived: !!shouldIncludeArchived,
    includePending: true,
    sortRecentFirst: true,
  };
  const audiences = useGetCurrentAudiences(getAudiencesParams);
  const hasAnyArchived = useGetCurrentAudiences({
    ...getAudiencesParams,
    includeArchived: true,
  }).some(a => a.isArchived);

  useEffect(() => {
    localStorage.setItem("shouldIncludeArchived", JSON.stringify(shouldIncludeArchived));
  }, [shouldIncludeArchived]);

  let filteredAudiences = audiences;
  if (audienceSearch) {
    filteredAudiences = filteredAudiences.filter(audience =>
      audience.name.toLowerCase().includes(audienceSearch.toLowerCase()),
    );
  }
  const audienceList = filteredAudiences.map(audience => (
    <AudienceListItem audience={audience} key={audience.id} />
  ));

  const headerRightContent = (
    <>
      <Grid item>
        {hasAnyArchived ? (
          <Button onClick={() => setShouldIncludeArchived(!shouldIncludeArchived)}>
            {shouldIncludeArchived ? "Hide" : "Show"} archived
          </Button>
        ) : null}
      </Grid>
      {/* @todo-ui-later add table view */}
      {/* <Grid item>
        <Button onClick={() => {}}>Table view</Button>
      </Grid> */}
    </>
  );

  let audienceListUi;
  if (filteredAudiences.length > 0) {
    // There are audiences to show
    audienceListUi = (
      <Grid container spacing={4}>
        {audienceList}
      </Grid>
    );
  } else if (filteredAudiences.length === 0 && (audiences.length > 0 || hasAnyArchived)) {
    // There are audiences, but they're not visible either because we're not showing archived ones
    // or the inputted search doesn't match any
    audienceListUi = <Box>No audiences found.</Box>;
  } else if (!hasAnyArchived) {
    audienceListUi = (
      <Box>
        To create your first audience, select one of these options:
        <ul>
          <li>
            <strong>
              <Link to="/audiences/create">Create an audience by applying filters</Link>
            </strong>
          </li>
          <li>
            <strong>
              <Link to="/audiences/enrich">Enrich a list by uploading a CSV</Link>
            </strong>
          </li>
        </ul>
      </Box>
    );
  }

  return (
    <>
      <AudiencesHeader
        alternateRightContent={headerRightContent}
        headerText="Audiences"
        showNewAudienceButton={false}
      />
      {audiences.length > 0 ? (
        <TextField
          InputProps={{
            endAdornment: (
              <XOutAdornment disabled={!audienceSearch} onClick={() => setAudienceSearch("")} />
            ),
          }}
          label="Search audiences"
          margin="dense"
          onChange={e => setAudienceSearch(e.target.value)}
          size="small"
          sx={{ marginBottom: "1rem" }}
          value={audienceSearch}
        />
      ) : null}
      {audienceListUi}
    </>
  );
};

export default AudienceList;
