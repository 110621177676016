import { Container } from "@mui/material";

import { COLORS } from "../../colors";

const INFO_BOX_TYPE_COLORS = {
  error: COLORS.lightRed,
  info: COLORS.veryLightMagenta,
  warning: COLORS.lightOrange,
  neutral: COLORS.lightGray,
};

// TODO just use type and don't take a backgroundColor prop at all
const InfoBox = ({
  color = "black",
  marginBottom = "0",
  marginTop = "1.5rem",
  type,
  typography = "body2",
  children,
}) => {
  // Use light gray as the default if no type is provided.
  const backgroundColor = INFO_BOX_TYPE_COLORS[type] || INFO_BOX_TYPE_COLORS.neutral;

  return (
    <Container
      sx={{
        backgroundColor,
        color: COLORS[color],
        padding: "1rem",
        marginTop,
        marginBottom,
        typography,
      }}
    >
      {children}
    </Container>
  );
};

export default InfoBox;
