import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";

import useGetActiveOrg from "../../hooks/useGetActiveOrg";
import ButtonModal from "../shared/ButtonModal";
import Loading from "../shared/Loading";

import CreateContentGroup from "./CreateContentGroup";
import CreateContentVariation from "./CreateContentVariation";
import VariationPreview from "./VariationPreview";

const CreateNewContentModal = ({ setIsOpen, handleNewContentIsReady }) => {
  const [newContentGroupTypedId, setNewContentGroupTypedId] = useState();
  const [isCreatingNewVariation, setIsCreatingNewVariation] = useState();

  const { organization } = useGetActiveOrg();
  const { contentGroups } = organization;
  const newContentGroup =
    contentGroups && contentGroups.find(cg => cg.typedId === newContentGroupTypedId);

  const handleContentWasCreated = newContentGroup => {
    setNewContentGroupTypedId(newContentGroup.typedId);
  };

  const handleVariationWasCreated = x => {
    setIsCreatingNewVariation(false);
  };

  let title = "Add new content";
  let mainContent;
  // This is if we're waiting for the newly created content group to percolate down from app state.
  if (newContentGroupTypedId && !newContentGroup) {
    return <Loading />;
  } else if (!newContentGroup) {
    mainContent = (
      <CreateContentGroup
        handleContentWasCreated={handleContentWasCreated}
        isInline={true}
        type="DigitalAd"
      />
    );
  } else if (isCreatingNewVariation) {
    title = "Add new content - new variation";
    mainContent = (
      <CreateContentVariation
        contentGroup={newContentGroup}
        handleContentWasCreated={handleVariationWasCreated}
        isInline={true}
      />
    );
  } else if (newContentGroup) {
    mainContent = (
      <>
        <Grid container spacing={2} sx={{ marginBottom: "2rem" }}>
          {newContentGroup.variations &&
            newContentGroup.variations.map(v => (
              <Grid item key={v.typedId} lg={3} sx={{ padding: "1.5rem 0" }} xs={4}>
                <VariationPreview includeName isLink={false} variation={v} />
              </Grid>
            ))}
        </Grid>
        <Box>
          <Button onClick={() => handleNewContentIsReady(newContentGroup)}>Use content</Button>
          <Typography sx={{ margin: "0 10px" }} variant="inline">
            or
          </Typography>
          <Button color="secondary" onClick={() => setIsCreatingNewVariation(true)}>
            Create another variation
          </Button>
        </Box>
      </>
    );
  }

  return (
    <ButtonModal
      isOpen={true}
      setIsOpen={setIsOpen}
      showCancel={false}
      showConfirm={false}
      title={title}
      width="95%"
    >
      {mainContent}
    </ButtonModal>
  );
};

export default CreateNewContentModal;
