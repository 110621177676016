import { Link } from "react-router-dom";

import useGetCurrentAudiences from "../../hooks/useGetCurrentAudiences";
import InfoBox from "../shared/InfoBox";

const WarningIfNoActiveAudiences = ({ alternateContent = null }) => {
  const audiences = useGetCurrentAudiences();
  const areActiveAudiencesPresent = audiences.length > 0;

  const requiredActions = [];
  if (!areActiveAudiencesPresent) {
    requiredActions.push(<Link to="/audiences">Create an audience</Link>);
  }

  let infoBoxWarning;
  if (requiredActions.length > 0) {
    let requiredActionsUi;
    if (requiredActions.length === 1) {
      requiredActionsUi = (
        <span style={{ textTransform: "lowercase" }}> {requiredActions[0]}.</span>
      );
    } else {
      requiredActionsUi = (
        <>
          :
          <ul>
            {requiredActions.map((actionLink, i) => (
              <li key={`required-action-link-${i}`}>{actionLink}</li>
            ))}
          </ul>
        </>
      );
    }
    infoBoxWarning = (
      <InfoBox marginBottom="1rem" type="warning" typography="body1">
        In order to evaluate content, you'll first need to{requiredActionsUi}
      </InfoBox>
    );
  }

  return infoBoxWarning || alternateContent;
};

export default WarningIfNoActiveAudiences;
