import { Box, Button, Grid, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { fromPairs, map } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { useAppState } from "../AppStateContext";
import { COLORS } from "../colors";
import { countAndNoun, userFriendlyCents, userFriendlyNumber } from "../helpers";
import useGetActiveOrg from "../hooks/useGetActiveOrg";
import useGetMinMaxCreditsPurchase from "../hooks/useGetMinMaxCreditsPurchase";

import PurchaseCreditsCheckout from "./PurchaseCreditsCheckout";
import InfoBox from "./shared/InfoBox";
import NumericField from "./shared/NumericField";
import SharedModal from "./shared/SharedModal";

const CreditsInfo = () => {
  const { constants } = useAppState();

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Item</strong>
              </TableCell>
              <TableCell>
                <strong>Price</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Voter file match</TableCell>
              <TableCell>
                {countAndNoun(constants.AUDIENCE_FIELD_PRICING.match.price, "credit")} per person
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Basic export</TableCell>
              <TableCell>
                {countAndNoun(constants.AUDIENCE_FIELD_PRICING.base_export.price, "credit")} per
                person
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email, phone, or address export</TableCell>
              <TableCell>
                {countAndNoun(constants.AUDIENCE_CONTACT_INFO_FIELDS_PRICE, "credit")} per person
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Content evaluation</TableCell>
              <TableCell>{countAndNoun(constants.CAMPAIGN_CREATION_PRICE, "credit")}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const PurchaseCredits = () => {
  const [numSelectedCredits, setNumSelectedCredits] = useState();
  const [numSelectedBudget, setNumSelectedBudget] = useState();
  const [isReadyForCheckout, setIsReadyForCheckout] = useState(false);
  const [shouldShowCreditsInfo, setShouldShowCreditsInfo] = useState(false);
  const { constants } = useAppState();
  const { organization } = useGetActiveOrg();
  const { creditTransactions } = organization;
  const { MIN_CREDITS_PURCHASE, MAX_CREDITS_PURCHASE, MAX_STRIPE_CHARGE_CENTS } =
    useGetMinMaxCreditsPurchase();
  const { returnPath } = useParams();

  const handleSetNumSelectedCredits = value => {
    setNumSelectedBudget(null);
    setNumSelectedCredits(value);
  };

  const handleSetNumSelectedBudget = value => {
    setNumSelectedCredits(null);
    setNumSelectedBudget(value);
  };

  const DOLLAR_PRICING_TIERS = useMemo(
    () =>
      fromPairs(
        map(constants.CREDIT_PRICING_TIERS, (tier, threshold) => [
          (threshold * tier.pricePerCredit) / 100,
          tier,
        ]),
      ),
    [constants.CREDIT_PRICING_TIERS],
  );

  // See get_price_per_credit in credit_transaction.py
  // Expects either numSelectedCredits OR numSelectedBudget.
  const getPricingTier = useCallback(
    ({ numSelectedCredits, numSelectedBudget }) => {
      const pricingTiers = numSelectedCredits
        ? constants.CREDIT_PRICING_TIERS
        : DOLLAR_PRICING_TIERS;
      const numSelectedCreditsOrBudget = numSelectedCredits
        ? numSelectedCredits
        : numSelectedBudget;
      return pricingTiers[
        Math.max(
          ...Object.keys(pricingTiers)
            .map(Number)
            .filter(threshold => numSelectedCreditsOrBudget >= threshold),
        )
      ];
    },
    [constants.CREDIT_PRICING_TIERS, DOLLAR_PRICING_TIERS],
  );

  // See get_price_info in credit_transaction.py
  // Expects either numSelectedCredits OR numSelectedBudget.
  const getPriceInfo = useCallback(
    ({ numSelectedCredits, numSelectedBudget }) => {
      let tier, totalPrice, totalCredits;
      if (numSelectedCredits) {
        tier = getPricingTier({ numSelectedCredits: numSelectedCredits || 0 });
        totalCredits = numSelectedCredits;
      } else if (numSelectedBudget) {
        tier = getPricingTier({ numSelectedBudget: numSelectedBudget || 0 });
        totalCredits = Math.floor((numSelectedBudget * 100) / tier.pricePerCredit);
      }
      totalPrice = Math.floor(totalCredits * tier.pricePerCredit);
      return { ...tier, totalPrice, totalCredits };
    },
    [getPricingTier],
  );

  const defaultPriceInfoRef = useRef(null);
  const priceInfo = useMemo(() => {
    let newPriceInfo;
    if (numSelectedBudget) {
      newPriceInfo = getPriceInfo({ numSelectedBudget });
    } else if (numSelectedCredits) {
      newPriceInfo = getPriceInfo({ numSelectedCredits });
    } else {
      // This (i.e. `numSelectedBudget` and `numSelectedCredits` both being non-null temporarily)
      // shouldn't be possible, but if the state updates in e.g. `handleSetNumSelectedCredits`
      // somehow aren't batched, maybe it could happen.
      newPriceInfo = defaultPriceInfoRef.current;
    }
    defaultPriceInfoRef.current = newPriceInfo;
    return newPriceInfo;
  }, [numSelectedCredits, numSelectedBudget, getPriceInfo]);

  const numSelectedCreditsIsValid = useMemo(
    () =>
      numSelectedCredits &&
      numSelectedCredits >= MIN_CREDITS_PURCHASE &&
      numSelectedCredits <= MAX_CREDITS_PURCHASE,
    [numSelectedCredits, MAX_CREDITS_PURCHASE, MIN_CREDITS_PURCHASE],
  );
  const numSelectedBudgetIsValid = useMemo(
    () =>
      numSelectedBudget &&
      numSelectedBudget >= 1 &&
      numSelectedBudget <= MAX_STRIPE_CHARGE_CENTS / 100,
    [numSelectedBudget, MAX_STRIPE_CHARGE_CENTS],
  );
  const isSelectedBudgetOrCreditsValid = numSelectedCreditsIsValid || numSelectedBudgetIsValid;

  const pricingInfo = (
    <>
      <TableContainer sx={{ marginTop: "10px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Number of credits</strong>
              </TableCell>
              <TableCell>
                <strong>Price per credit</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(constants.CREDIT_PRICING_TIERS, (tier, threshold) => (
              <TableRow
                key={threshold}
                sx={{
                  backgroundColor:
                    isSelectedBudgetOrCreditsValid &&
                    priceInfo.volumeDiscountPercentage === tier.volumeDiscountPercentage
                      ? COLORS.lightGreen
                      : "inherit",
                }}
              >
                <TableCell>{userFriendlyNumber(Number(threshold))}+</TableCell>
                <TableCell>
                  {tier.pricePerCredit}¢{tier.volumeDiscountDisplay}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  let errorInfoBox;
  if (numSelectedCredits && !numSelectedCreditsIsValid) {
    errorInfoBox = (
      <InfoBox type="error">
        <Typography>
          Number of credits must be between {MIN_CREDITS_PURCHASE} and{" "}
          {userFriendlyNumber(MAX_CREDITS_PURCHASE)}
        </Typography>
      </InfoBox>
    );
  }
  let infoBoxContent = <Typography>Select a number of credits to view your price.</Typography>;
  if (priceInfo && priceInfo.totalPrice && isSelectedBudgetOrCreditsValid) {
    infoBoxContent = (
      <>
        <Typography>
          <strong>{userFriendlyNumber(priceInfo.totalCredits)}</strong> credits will cost{" "}
          {priceInfo.volumeDiscountPercentage > 0 ? (
            <>
              <span style={{ textDecoration: "line-through" }}>
                {userFriendlyCents(
                  priceInfo.totalCredits * constants.CREDIT_PRICING_TIERS["0"].pricePerCredit,
                )}
              </span>{" "}
            </>
          ) : null}
          <strong>{userFriendlyCents(priceInfo.totalPrice)}</strong>
        </Typography>
      </>
    );
  }
  const infoBox = (
    <InfoBox marginTop="0">
      {infoBoxContent}
      {pricingInfo}
      <Box marginTop="1rem">
        <Link onClick={() => setShouldShowCreditsInfo(true)} size="small">
          What can I buy with credits?
        </Link>
      </Box>
    </InfoBox>
  );

  let creditTransactionsLink;
  if (creditTransactions && creditTransactions.length > 0) {
    creditTransactionsLink = (
      <Box marginTop="1rem">
        <Link to="/credits/transactions">View credit transaction history</Link>
      </Box>
    );
  }

  return (
    <>
      <Typography variant="h2">Purchase credits for your organization:</Typography>
      <SharedModal isOpen={isReadyForCheckout} setIsOpen={setIsReadyForCheckout} width={600}>
        <PurchaseCreditsCheckout
          numCredits={priceInfo && priceInfo.totalCredits}
          returnPath={returnPath}
        />
      </SharedModal>
      <SharedModal isOpen={shouldShowCreditsInfo} setIsOpen={setShouldShowCreditsInfo} width={600}>
        <CreditsInfo />
      </SharedModal>
      <Grid container alignItems="baseline" columnSpacing={2}>
        <Grid item>
          <Box>
            <NumericField
              label="Number of credits"
              setValue={handleSetNumSelectedCredits}
              value={numSelectedCredits}
            />
          </Box>
          <Box sx={{ margin: "1rem 0" }}>or</Box>
          <Box>
            <NumericField
              isDollar
              label="Budget in dollars"
              maxLength={6}
              setValue={handleSetNumSelectedBudget}
              value={numSelectedBudget}
            />
          </Box>
        </Grid>
        <Grid item xs>
          {infoBox}
        </Grid>
      </Grid>
      {errorInfoBox}
      <Box>
        <Button
          disabled={!isSelectedBudgetOrCreditsValid}
          onClick={() => setIsReadyForCheckout(true)}
          sx={{ marginTop: "1rem" }}
        >
          Purchase credits
        </Button>
      </Box>
      {creditTransactionsLink}
    </>
  );
};

export default PurchaseCredits;
