import { countAndNoun } from "../../helpers";
import useGetCurrentAudiences from "../../hooks/useGetCurrentAudiences";
import SelectOrgRelatedObject from "../shared/SelectOrgRelatedObject";

// note: selectedAudience must be null instead of undefined.
// @todo consider refactoring this to base it on DropdownSelectOne (just for consistency). this
// would require defining the options prop the way that other component does: just a list of
// value/label tuples
const SelectAudience = ({
  selectedAudience,
  setSelectedAudience,
  isDisabled = false,
  disableIfNoAudiences = false,
  label = "Select an existing audience",
  placeholder = "",
  subtitle = null,
  additionalAudiencesFilter = () => true,
  width = null,
}) => {
  const audiences = useGetCurrentAudiences().filter(additionalAudiencesFilter);
  const getOptionLabel = audience =>
    audience.metadata
      ? `${audience.name} (${countAndNoun(audience.metadata.size, "person")})`
      : audience.name;

  return (
    <SelectOrgRelatedObject
      options={audiences}
      selectedObject={selectedAudience}
      setSelectedObject={setSelectedAudience}
      {...{ getOptionLabel, isDisabled, label, placeholder, subtitle, width }}
      isDisabled={isDisabled || (disableIfNoAudiences && audiences.length === 0)}
    />
  );
};

export default SelectAudience;
