import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";

import { formatPercentage, userFriendlyNumber } from "../../helpers";

const formatPctCell =
  ({ field, invert = false }) =>
  params =>
    formatPercentage({ value: invert ? 1 - params.row[field] : params.row[field], sigFig: 2 });

const CsvMatchResults = ({ csv, metadata }) => {
  const rows = metadata.columnMatchResults
    .map(row => ({
      ...row,
      required: csv.columnSelections[row.column].is_required ? "Required" : "Optional",
    }))
    .sort((a, b) =>
      // Show required first and then sort alphabetically
      a.required === b.required
        ? a.column.localeCompare(b.column)
        : b.required.localeCompare(a.required),
    );

  const tableColumnSpecs = [
    {
      field: "column",
      headerName: "Field",
      valueGetter: params => {
        const field = params.row.column;
        const originalColumnName = csv.columnSelections[field].column_name;
        return field === originalColumnName ? field : `${field} (${originalColumnName})`;
      },
      width: 250,
    },
    {
      field: "required",
      headerName: "Match required?",
      width: 125,
    },
    {
      field: "percentMissing",
      headerName: "% of rows with data",
      valueGetter: formatPctCell({ field: "percentMissing", invert: true }),
      width: 150,
    },
    {
      field: "percentOfMatches",
      headerName: "% of matches using",
      valueGetter: formatPctCell({ field: "percentOfMatches" }),
      width: 200,
    },
  ];

  const overallDetails = [
    <>
      <b>Filename:</b> {csv.name}
    </>,
    <>
      <b>Rows in uploaded file:</b> {userFriendlyNumber(csv.numDataRows)}
    </>,
    <>
      <b>Matches:</b> {userFriendlyNumber(metadata.numMatches)} (
      {userFriendlyNumber(metadata.numUniqueMatches)} unique)
    </>,
    <>
      <b>Match rate:</b>{" "}
      {formatPercentage({
        value: metadata.numMatches / csv.numDataRows,
        sigFig: 2,
      })}
    </>,
  ];

  const perFieldRenderedColumns = tableColumnSpecs.map(spec => ({
    ...spec,
    renderHeader: params => (
      <Typography variant="columnHeader">{params.colDef.headerName}</Typography>
    ),
    sortable: false, // setting disableColumnSorting on DataGrid must be a "pro" feature
  }));
  return (
    <>
      {overallDetails.map((d, i) => (
        <Typography key={`matching-detail-${i}`}>{d}</Typography>
      ))}
      <Typography sx={{ marginTop: 2 }}>
        The table below shows a summary of each column's impact on the matching process.
        <br />
        <br />
        The "% of matches using" value reflects the percentage of successfully matched rows for
        which the given column's value matched the corresponding value in the voter file record that
        was selected as a match for this row. This will always be 100% for any field that you
        specified as required.
      </Typography>
      <DataGrid
        disableColumnFilter
        disableColumnMenu
        hideFooter
        columns={perFieldRenderedColumns}
        rows={rows}
        rowSelection={false}
        sx={{ marginTop: 5 }}
      />
    </>
  );
};

export default CsvMatchResults;
