import { SvgIcon } from "@mui/material";

const SmileIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg height="24" viewBox="0 0 24 24" width="24">
        <g clipPath="url(#clip0_7547_194)" id="g1" transform="translate(1.9985255,2.0029762)">
          <path
            d="m 10.0015,0.00634766 c -5.51766,0 -9.990651,4.47299234 -9.990651,9.99063234 0,5.51772 4.472991,9.99072 9.990651,9.99072 5.5177,0 9.9906,-4.473 9.9906,-9.99072 0,-5.51764 -4.4729,-9.99063234 -9.9906,-9.99063234 z M 13.748,5.0017 c 0.6897,0 1.2488,0.55906 1.2488,1.2488 0,0.68974 -0.5591,1.24887 -1.2488,1.24887 -0.6897,0 -1.2488,-0.55913 -1.2488,-1.24887 0,-0.68974 0.5591,-1.2488 1.2488,-1.2488 z m -7.49298,0 c 0.6897,0 1.24882,0.55906 1.24882,1.2488 0,0.68974 -0.55912,1.24887 -1.24882,1.24887 -0.68972,0 -1.24884,-0.55913 -1.24884,-1.24887 0,-0.68974 0.55912,-1.2488 1.24884,-1.2488 z M 10.0015,17.49 c -4.13825,0 -7.49298,-3.3547 -7.49298,-7.49302 h 2.49766 c 0,2.75892 2.2365,4.99532 4.99532,4.99532 2.7589,0 4.9953,-2.2364 4.9953,-4.99532 h 2.4977 c 0,4.13832 -3.3547,7.49302 -7.493,7.49302 z"
            fill="currentColor"
            id="path1"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default SmileIcon;
