import { useAppState } from "../AppStateContext";
import { userFriendlyNumber } from "../helpers";

const useValidAudiencesForLookalikeCopy = () => {
  const { constants } = useAppState();
  return (
    <>
      To be eligible for lookalike expansion, an audience must be based on matching at least{" "}
      {userFriendlyNumber(constants.MIN_AUDIENCE_SIZE_FOR_LOOKALIKE)} people to the voter file from
      an uploaded CSV. This includes audiences filtered from CSV-matched audiences.
    </>
  );
};

export default useValidAudiencesForLookalikeCopy;
