import CheckIcon from "@mui/icons-material/Check";
import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import Loading from "./Loading";

// In milliseconds.
const TIME_TO_CHECK_OFF_STEP_ONCE_COMPLETE = 0.09 * 1000;

// If `contentToRenderWhenComplete` is passed, that's what we'll show when we're done loading.
// If it's not passed, we'll render nothing and assume the parent handles the non-loading state.
const LoadingExtended = ({
  steps,
  completedStep,
  contentToRenderWhenComplete,
  title = "Loading",
}) => {
  // This is set to true once we've briefly shown the final state with all the steps checked.
  const [isDoneShowingFinalState, setIsDoneShowingFinalState] = useState();
  const isParentFinalStepCompleted = completedStep >= steps.length - 1;

  // Show final step for TIME_TO_CHECK_OFF_STEP_ONCE_COMPLETE and then render `contentToRenderWhenComplete`.
  useEffect(() => {
    if (isParentFinalStepCompleted) {
      setTimeout(() => setIsDoneShowingFinalState(true), TIME_TO_CHECK_OFF_STEP_ONCE_COMPLETE);
    }
  }, [completedStep, isParentFinalStepCompleted]);

  // If the final step isn't completed and isDoneShowingFinalState is true,
  // that means we're re-loading and need to set isDoneShowingFinalState to false.
  useEffect(() => {
    if (!isParentFinalStepCompleted && isDoneShowingFinalState) {
      setIsDoneShowingFinalState(false);
    }
  }, [isParentFinalStepCompleted, isDoneShowingFinalState]);

  if (isParentFinalStepCompleted && isDoneShowingFinalState) {
    return contentToRenderWhenComplete || null;
  } else {
    return (
      <>
        <Typography sx={{ textAlign: "left" }} variant="h3">
          {title}
        </Typography>
        {steps.map((step, index) => (
          <Grid container columnSpacing={2} key={index}>
            <Grid item>
              {completedStep < index ? (
                <Loading animation={completedStep === index - 1} />
              ) : (
                <CheckIcon
                  sx={{
                    height: "28px",
                    width: "28px",
                  }}
                />
              )}
            </Grid>
            <Grid item xs sx={{ textAlign: "left" }}>
              {step}
            </Grid>
          </Grid>
        ))}
      </>
    );
  }
};

export default LoadingExtended;
