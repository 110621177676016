import { useQuery, useMutation, gql } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import { sortBy } from "lodash";
import { useState, useMemo } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppState, useAppStateDispatch } from "../../AppStateContext";
import DropdownSelectOne from "../shared/DropdownSelectOne";
import Loading from "../shared/Loading";

import OrgApiKeys from "./OrgApiKeys";

export const ORGANIZATION_MEMBERSHIP = gql`
  fragment OrganizationMembership on OrganizationMembership {
    id
    isManager
    activeInvite {
      id
      expiresAt
      isOrgManager
    }
    user {
      id
      isApiUser
      firstName
      lastName
      email
      isActive
    }
  }
`;

const GET_ADMIN_ORGANIZATIONS = gql`
  ${ORGANIZATION_MEMBERSHIP}
  query getAdminOrganizations {
    adminOrganizations {
      id
      name
      apiKeys {
        id
        token
        createdAt
      }
      memberships {
        ...OrganizationMembership
      }
    }
  }
`;

const ADMIN_INVITE_USER = gql`
  ${ORGANIZATION_MEMBERSHIP}
  mutation AdminInviteUser($email: String!, $orgId: ID!, $isManager: Boolean!) {
    adminInviteUser(email: $email, orgId: $orgId, isManager: $isManager) {
      ... on InviteUserSuccess {
        organizationMembership {
          ...OrganizationMembership
        }
      }
      ... on InviteUserError {
        message
      }
    }
  }
`;

const ADMIN_RESEND_INVITE_USER = gql`
  ${ORGANIZATION_MEMBERSHIP}
  mutation AdminResendInviteUser($membershipId: ID!) {
    adminResendInviteUser(membershipId: $membershipId) {
      ... on InviteUserSuccess {
        organizationMembership {
          ...OrganizationMembership
        }
      }
      ... on InviteUserError {
        message
      }
    }
  }
`;

const ADMIN_REMOVE_USER_FROM_ORG = gql`
  mutation AdminRemoveUserFromOrg($membershipId: ID!) {
    adminRemoveUserFromOrg(membershipId: $membershipId) {
      ... on ErrorResponse {
        message
      }
    }
  }
`;

const InviteUser = ({ organization }) => {
  const dispatch = useAppStateDispatch();
  const [email, setEmail] = useState("");
  const [isManager, setIsManager] = useState(false);

  const [doInviteUserMutation, { loading }] = useMutation(ADMIN_INVITE_USER);

  const handleInviteUser = event => {
    event.preventDefault();
    doInviteUserMutation({
      variables: { email, orgId: organization.id, isManager },
      onCompleted: data => {
        if (data.adminInviteUser.organizationMembership) {
          dispatch({
            type: "admin-organizations-add-membership",
            organizationId: organization.id,
            newMembership: data.adminInviteUser.organizationMembership,
          });
          setEmail("");
          toast("User invited.", { type: "success" });
        } else if (data.adminInviteUser.message) {
          toast(data.adminInviteUser.message, { type: "error" });
        }
      },
    });
  };

  return loading ? (
    <Loading />
  ) : (
    <form onSubmit={handleInviteUser}>
      <div>
        <label htmlFor="email">Email: </label>
        <input name="email" onChange={e => setEmail(e.target.value)} type="text" value={email} />
        <br />
        <input
          checked={isManager}
          name="isManager"
          onChange={e => setIsManager(!isManager)}
          type="checkbox"
        />
        <label htmlFor="isManager">
          Is org manager? (NB: this doesn't let the user do anything extra currently, but it
          probably will at some point.)
        </label>
      </div>
      <Button disabled={!email} sx={{ marginTop: "10px" }} type="submit">
        Invite User
      </Button>
    </form>
  );
};

const OrgMembership = ({ organizationId, membership }) => {
  const dispatch = useAppStateDispatch();
  const [doResendInvite, { loading: resendInviteLoading }] = useMutation(ADMIN_RESEND_INVITE_USER);
  const [doRemoveUserFromOrg, { loading: removeUserLoading }] = useMutation(
    ADMIN_REMOVE_USER_FROM_ORG,
  );

  const handleResendInvite = () => {
    doResendInvite({
      variables: { membershipId: membership.id },
      onCompleted: data => {
        if (data.adminResendInviteUser.organizationMembership) {
          dispatch({
            type: "admin-organizations-update-membership",
            organizationId: organizationId,
            updatedMembership: data.adminResendInviteUser.organizationMembership,
          });
          toast("Invite re-sent.", { type: "success" });
        } else if (data.adminResendInviteUser.message) {
          toast(data.adminResendInviteUser.message, { type: "error" });
        }
      },
    });
  };

  const handleRemoveUserFromOrg = () => {
    const confirmed = window.confirm(
      "Are you sure you want to remove the user from the organization?",
    );
    if (!confirmed) {
      return;
    }
    doRemoveUserFromOrg({
      variables: { membershipId: membership.id },
      onCompleted: data => {
        if (data.adminRemoveUserFromOrg && data.adminRemoveUserFromOrg.message) {
          toast(data.adminRemoveUserFromOrg.message, { type: "error" });
        } else {
          dispatch({
            type: "admin-organizations-remove-membership",
            organizationId: organizationId,
            membershipId: membership.id,
          });
          toast("User removed.", { type: "success" });
        }
      },
    });
  };

  const membershipActionIsLoading = resendInviteLoading || removeUserLoading;

  return (
    <li key={membership.id}>
      {membership.user.isActive ? "✅" : "🔄"} {membership.user.email} |{" "}
      {membership.user.firstName || membership.user.lastName
        ? ` ${membership.user.firstName} ${membership.user.lastName} | `
        : null}
      Manager: {membership.isManager ? "yes" : "no"} | Invite expired:{" "}
      {membership.activeInvite
        ? new Date(membership.activeInvite.expiresAt) < new Date()
          ? "yes"
          : "no"
        : null}
      {" | "}
      Resend invite:{" "}
      {membership.user.isActive ? (
        "Already accepted"
      ) : (
        <button
          disabled={membershipActionIsLoading}
          onClick={() => handleResendInvite(membership.user)}
        >
          Resend
        </button>
      )}
      {" | "}
      <button
        disabled={membershipActionIsLoading || membership.user.isApiUser}
        onClick={() => handleRemoveUserFromOrg(membership.user)}
      >
        Remove from org
      </button>
    </li>
  );
};

const OrgDetail = ({ organization }) => {
  const sortedMemberships = sortBy(organization.memberships, u => parseInt(u.id));

  return (
    <>
      <Typography variant="h2">
        {organization.name} (ID: {organization.id})
      </Typography>
      <OrgApiKeys organization={organization} />
      <Typography sx={{ marginTop: "20px" }} variant="h3">
        Members:
      </Typography>
      {/* @todo use a table here, and make it sortable by date added to org, name, email */}
      <ul>
        {sortedMemberships.map(membership => (
          <OrgMembership
            key={membership.id}
            membership={membership}
            organizationId={organization.id}
          />
        ))}
      </ul>
    </>
  );
};

const OrgAdmin = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const { adminOrganizations } = useAppState();
  const dispatch = useAppStateDispatch();

  const { loading } = useQuery(GET_ADMIN_ORGANIZATIONS, {
    onCompleted: data =>
      dispatch({ type: "admin-organizations-set", adminOrganizations: data.adminOrganizations }),
  });

  const orgToSelectOption = org => ({ value: org.id, label: org.name });

  const organizationOptions = useMemo(
    () => adminOrganizations && Object.values(adminOrganizations).map(orgToSelectOption),
    [adminOrganizations],
  );

  const selectedOrganization = adminOrganizations && adminOrganizations[orgId];

  let content;
  if (loading || !organizationOptions) {
    content = <Loading />;
  } else {
    let orgDetails;
    if (orgId && !selectedOrganization) {
      orgDetails = <>Organization not found</>;
    } else if (selectedOrganization) {
      orgDetails = (
        <>
          <OrgDetail organization={selectedOrganization} />
          <Typography variant="h3">Invite new user:</Typography>
          <InviteUser organization={(Box, selectedOrganization)} />
        </>
      );
    }

    content = (
      <>
        <Box>
          <Button component={Link} to="/admin/org/create">
            Create new organization
          </Button>
        </Box>
        <Box maxWidth="sm" sx={{ margin: "1rem 0" }}>
          Or select existing:
          <DropdownSelectOne
            isDisabled={loading}
            onChange={orgId => navigate(`/admin/org/${orgId}`)}
            options={organizationOptions}
            placeholder="Select an organization"
            value={selectedOrganization && selectedOrganization.id}
          />
        </Box>
        <hr />
        {orgDetails}
      </>
    );
  }

  return (
    <>
      <Typography variant="h2">Organization admin</Typography>
      <div>{content}</div>
    </>
  );
};

export default OrgAdmin;
