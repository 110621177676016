import { SvgIcon } from "@mui/material";

const CheckboxIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg height="24" viewBox="0 0 20 19" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 1.796106,2.0000001 V 17 H 18.203894 V 2.0000001 Z M 10.703894,15.125003 H 10.233647 6.9538451 6.4836229 L 3.6711145,9.4999836 H 7.4211386 L 8.5937463,11.845252 12.578898,3.8749922 h 3.749998 z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default CheckboxIcon;
