import { gql, useMutation } from "@apollo/client";
import { Box, Button, FormHelperText, Grid, Slider, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CarouselSlider from "react-slick";

import { useAppState, useAppStateDispatch } from "../../AppStateContext";
import { FormSection } from "../../common/layout";
import { CONTENT_GROUP_TYPES } from "../../constants";
import { CORE_OUTREACH_CAMPAIGN_FIELDS } from "../../fragments";
import {
  countAndNoun,
  doesApolloErrorIncludeErrorOfType,
  formatDateForBackend,
  formatDateTimeForBackend,
  userFriendlyWholeDollars,
} from "../../helpers";
import useFormErrors from "../../hooks/useFormErrors";
import useGetActiveOrg from "../../hooks/useGetActiveOrg";
import useGetAudienceFromParam from "../../hooks/useGetAudienceFromParam";
import useGetContentGroupFromParam from "../../hooks/useGetContentGroupFromParam";
import useRefreshOrgCredits from "../../hooks/useRefreshOrgCredits";
import SelectNationalVoterFileOrExistingAudience from "../audience/SelectNationalVoterFileOrExistingAudience";
import CreateNewContentModal from "../content/CreateNewContentModal";
import SelectContentGroup from "../content/SelectContentGroup";
import VariationPreview from "../content/VariationPreview";
import DropdownSelectOne from "../shared/DropdownSelectOne";
import InfoBox from "../shared/InfoBox";
import MatchbookDatePicker from "../shared/MatchbookDatePicker";
import PurchaseCreditsDirectly from "../shared/PurchaseCreditsDirectly";
import TwoColumnLayoutWithPreview from "../shared/TwoColumnLayoutWithPreview";

import WarningIfNoActiveAudiencesOrContent from "./WarningIfNoActiveAudiences";

const CAROUSEL_SETTINGS = {
  dots: true,
  infinite: true,
  speed: 250,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const DEFAULT_BUDGET = 3000;

const CREATE_OUTREACH_CAMPAIGN = gql`
  ${CORE_OUTREACH_CAMPAIGN_FIELDS}
  mutation CreateOutreachCampaign($input: OutreachCampaignInput!) {
    createOutreachCampaign(input: $input) {
      ...CoreOutreachCampaignFields
      organization {
        id
        numCredits
      }
    }
  }
`;

// @todo-payments consolidate with AudienceDetail export UI which is similar.
const CampaignPricingInfo = () => {
  const { organization } = useGetActiveOrg();
  const { constants } = useAppState();

  if (organization.isExemptFromPayments) {
    return null;
  }

  const isError = organization.numCredits < constants.CAMPAIGN_CREATION_PRICE;
  const purchaseLink = isError ? (
    <PurchaseCreditsDirectly
      numCredits={constants.CAMPAIGN_CREATION_PRICE}
      returnPath="/evaluations/new"
    />
  ) : null;
  return (
    <InfoBox marginBottom="1.5rem" type={isError ? "error" : "warning"} typography="body1">
      To evaluate content, you must use {countAndNoun(constants.CAMPAIGN_CREATION_PRICE, "credit")}.
      {purchaseLink}
    </InfoBox>
  );
};

const CreateOutreachCampaign = () => {
  const dispatch = useAppStateDispatch();
  const location = useLocation();

  const navigate = useNavigate();
  const { formErrors, validateFieldIsPresent, updateFormError } = useFormErrors();
  const { constants } = useAppState();

  const { organization } = useGetActiveOrg();
  const selectedAudience = useGetAudienceFromParam();
  const selectedContentGroup = useGetContentGroupFromParam();
  const searchParams = new URLSearchParams(location.search);
  const isStartFromExisting = searchParams.get("startFromExisting") !== null;

  const [isCreateNewContentModalOpen, setIsCreateNewContentModalOpen] = useState();
  const [newContentGroupTypedId, setNewContentGroupTypedId] = useState();

  const [budget, setBudget] = useState(DEFAULT_BUDGET);
  // @todo may need to remove these default start/end dates once we add additional content
  // types (i.e. ones that don't have a delivery start/end date).
  const [deliveryDateStart, setDeliveryDateStart] = useState(dayjs());
  const [deliveryDateEnd, setDeliveryDateEnd] = useState(dayjs().add(2, "week"));
  const [deliveryDateTime, setDeliveryDateTime] = useState(null);
  // const [actblueCode, setActblueCode] = useState("");
  const [platform, setPlatform] = useState(null);

  const carouselRef = useRef(null);

  const selectedContentType = selectedContentGroup && selectedContentGroup.type;
  const isSelectedContentTextMessage = selectedContentType === "TextMessage";
  const platformOptionsForContentType = useMemo(
    () => selectedContentType && constants.CAMPAIGN_PLATFORMS[selectedContentType],
    [selectedContentType, constants],
  );

  const refreshOrgCredits = useRefreshOrgCredits();

  const insufficientCredits =
    organization.numCredits < constants.CAMPAIGN_CREATION_PRICE &&
    !organization.isExemptFromPayments;

  // Select a default platform based on the options for the currently selected content type.
  useEffect(() => {
    if (platformOptionsForContentType) {
      setPlatform(platformOptionsForContentType[0].value);
    } else {
      setPlatform(null);
    }
  }, [platformOptionsForContentType]);

  const [doCreateOutreachCampaign, { loading }] = useMutation(CREATE_OUTREACH_CAMPAIGN);

  const createOutreachCampaign = () => {
    let validationFailed;
    if (isSelectedContentTextMessage) {
      if (!validateFieldIsPresent("deliveryDateTime", deliveryDateTime)) {
        validationFailed = true;
      }
    } else {
      if (!validateFieldIsPresent("deliveryDateStart", deliveryDateStart)) {
        validationFailed = true;
      }
      if (!validateFieldIsPresent("deliveryDateEnd", deliveryDateEnd)) {
        validationFailed = true;
      }
    }
    if (validationFailed) {
      return;
    }
    if (!organization.isExemptFromPayments) {
      const confirmed = window.confirm(
        `Are you sure you want to use ${countAndNoun(
          constants.CAMPAIGN_CREATION_PRICE,
          "credit",
        )} to evaluate your content?`,
      );
      if (!confirmed) {
        return;
      }
    }
    doCreateOutreachCampaign({
      variables: {
        input: {
          organizationId: organization.id,
          audienceId: selectedAudience && selectedAudience.id,
          contentGroupTypedId: selectedContentGroup.typedId,
          budget: isSelectedContentTextMessage ? null : budget,
          actblueCode: null,
          platform,
          deliveryDateStart: !isSelectedContentTextMessage
            ? formatDateForBackend(deliveryDateStart)
            : null,
          deliveryDateEnd: !isSelectedContentTextMessage
            ? formatDateForBackend(deliveryDateEnd)
            : null,
          deliveryDateTime: isSelectedContentTextMessage
            ? formatDateTimeForBackend(deliveryDateTime)
            : null,
        },
      },
      onCompleted: data => {
        dispatch({
          type: "org-add-or-update-outreach-campaign",
          organizationId: organization.id,
          outreachCampaign: data.createOutreachCampaign,
        });
        dispatch({
          type: "org-update-num-credits",
          organizationId: organization.id,
          numCredits: data.createOutreachCampaign.organization.numCredits,
        });
        // This dispatch is to 1. show the content group is locked in the UI and 2. show the
        // campaign as associated with the content group on the content group detail page.
        dispatch({
          type: "org-update-content-group",
          contentGroup: data.createOutreachCampaign.contentGroup,
          organizationId: data.createOutreachCampaign.organization.id,
        });
        navigate(`/evaluations/${data.createOutreachCampaign.id}`);
      },
      onError: e => {
        // @todo handle other possible errors?
        if (doesApolloErrorIncludeErrorOfType(e, "InsufficientCreditsAvailable")) {
          refreshOrgCredits();
        }
      },
    });
  };

  const setAudienceContentGroupAndIsStartFromExisting = ({
    newSelectedAudience = selectedAudience,
    newContentGroup = selectedContentGroup,
    newIsStartFromExisting = isStartFromExisting,
  }) => {
    // Go to the first slide in the carousel when changing content.
    if (
      carouselRef.current &&
      newContentGroup &&
      selectedContentGroup &&
      newContentGroup.typedId !== selectedContentGroup.typedId
    ) {
      carouselRef.current.slickGoTo(0);
    }
    const audiencePath = newSelectedAudience ? `/audience/${newSelectedAudience.id}` : "";
    const contentGroupPath = newContentGroup ? `/content/${newContentGroup.typedId}` : "";
    const startFromExistingParam =
      newIsStartFromExisting && !newSelectedAudience ? "?startFromExisting" : "";
    navigate(`/evaluations/new${audiencePath}${contentGroupPath}${startFromExistingParam}`);
  };

  const handleNewContentIsReady = newContentGroup => {
    setNewContentGroupTypedId(newContentGroup.typedId);
    setAudienceContentGroupAndIsStartFromExisting({ newContentGroup });
    setIsCreateNewContentModalOpen(false);
  };

  const isSubmitDisabled =
    Object.keys(formErrors).length > 0 ||
    (!selectedAudience && isStartFromExisting) ||
    !selectedContentGroup ||
    loading ||
    insufficientCredits;

  let budgetDisplay, deliveryDateDisplay;
  if (isSelectedContentTextMessage) {
    deliveryDateDisplay = (
      <>
        <Grid item xs={4}>
          Delivery date
        </Grid>
        <Grid container item xs={8}>
          <MatchbookDatePicker
            disablePast
            isDateTimePicker
            onChange={(newValue, context) => {
              validateFieldIsPresent("deliveryDateTime", newValue);
              if (context.validationError) {
                updateFormError("deliveryDateTime", "Invalid date or time");
              } else {
                setDeliveryDateTime(newValue);
              }
            }}
            value={deliveryDateTime}
          />
          <FormHelperText style={{ color: "red", marginLeft: "5px", marginTop: "8px" }}>
            {formErrors.deliveryDateTime}
          </FormHelperText>
        </Grid>
      </>
    );
  } else {
    budgetDisplay = (
      <>
        <Grid item xs={4}>
          Budget
        </Grid>
        <Grid item xs={8}>
          <Slider
            getAriaLabel={() => "Budget"}
            getAriaValueText={v => v}
            marks={[
              {
                value: budget,
                label: userFriendlyWholeDollars(budget),
              },
            ]}
            max={constants.CAMPAIGN_BUDGET_MAX}
            min={constants.CAMPAIGN_BUDGET_MIN}
            onChange={(e, newBudget) => setBudget(newBudget)}
            step={25}
            value={budget}
            valueLabelDisplay="off"
          />
        </Grid>
      </>
    );
    deliveryDateDisplay = (
      <>
        <Grid item xs={4}>
          Delivery dates
        </Grid>
        {/* @todo (applies above too)
          1. add red border when in error state
          2. avoid over-eagerly showing "invalid date" while manually entering date
          3. extract a "delivery date" picker component for start and end (maybe not necessary)
        */}
        <Grid container item xs={8}>
          <Grid item sx={{ paddingRight: "4px" }} xs={6}>
            <MatchbookDatePicker
              disablePast
              maxDate={deliveryDateEnd}
              onChange={(newValue, context) => {
                validateFieldIsPresent("deliveryDateStart", newValue);
                if (context.validationError) {
                  updateFormError("deliveryDateStart", "Invalid date");
                } else {
                  setDeliveryDateStart(newValue);
                }
              }}
              slotProps={{ textField: { label: "From" } }}
              value={deliveryDateStart}
            />
            <FormHelperText style={{ color: "red" }}>{formErrors.deliveryDateStart}</FormHelperText>
          </Grid>
          <Grid item sx={{ paddingLeft: "4px" }} xs={6}>
            <MatchbookDatePicker
              disablePast
              minDate={deliveryDateStart}
              onChange={(newValue, context) => {
                validateFieldIsPresent("deliveryDateEnd", newValue);
                if (context.validationError) {
                  updateFormError("deliveryDateEnd", "Invalid date");
                } else {
                  setDeliveryDateEnd(newValue);
                }
              }}
              slotProps={{ textField: { label: "To" } }}
              value={deliveryDateEnd}
            />
            <FormHelperText style={{ color: "red" }}>{formErrors.deliveryDateEnd}</FormHelperText>
          </Grid>
        </Grid>
      </>
    );
  }

  const platformLabelDisplay =
    selectedContentType && CONTENT_GROUP_TYPES[selectedContentType].platformLabelDisplay;
  let selectPlatformDisplay;
  if (platformLabelDisplay && platformOptionsForContentType) {
    selectPlatformDisplay = (
      <>
        <Grid item xs={4}>
          {platformLabelDisplay}
        </Grid>
        <Grid item xs={8}>
          <DropdownSelectOne
            onChange={value => setPlatform(value)}
            options={platformOptionsForContentType}
            // Ensure we don't have any invalid options while switching between content types
            value={platformOptionsForContentType.find(o => o.value === platform) ? platform : null}
          />
        </Grid>
      </>
    );
  }

  let usingNewContentInfoBox;
  if (selectedContentGroup && newContentGroupTypedId === selectedContentGroup.typedId) {
    usingNewContentInfoBox = (
      <InfoBox type="info">You're evaluating the content that you just created.</InfoBox>
    );
  }

  // Hiding actblue code for now since we aren't doing anything with it
  const actblueCodeDisplay = null;
  // const actblueCodeDisplay = <>
  //   <Grid item xs={4}>
  //     ActBlue code
  //   </Grid>
  //   <Grid item xs={8}>
  //     <TextField
  //       fullWidth
  //       autoComplete="off"
  //       inputProps={{ maxLength: 50 }}
  //       margin="dense"
  //       name="outreach-campaign-actblue-code"
  //       onChange={e => {
  //         setActblueCode(e.target.value);
  //       }}
  //       size="small"
  //       value={actblueCode}
  //     />
  //   </Grid>
  // </>

  // @todo this text field is very similar to the one in CreateContentGroupTextWithImage
  const mainContent = (
    <Box maxWidth="sm">
      <Typography variant="h5">Select or create your content</Typography>
      <FormSection>
        <SelectContentGroup
          placeholder="Select content"
          selectedContentGroup={selectedContentGroup}
          setSelectedContentGroup={newContentGroup =>
            setAudienceContentGroupAndIsStartFromExisting({ newContentGroup })
          }
          showLabel={false}
        />
        <Box sx={{ marginTop: "1rem" }}>
          <Button color="secondary" onClick={() => setIsCreateNewContentModalOpen(true)}>
            Add new content
          </Button>
        </Box>
        {usingNewContentInfoBox}
      </FormSection>

      <Typography variant="h5">Select your audience</Typography>
      <FormSection>
        <SelectNationalVoterFileOrExistingAudience
          nationalVoterFileLabel="All adults"
          setSelectedAudienceAndIsStartFromExisting={setAudienceContentGroupAndIsStartFromExisting}
          {...{ selectedAudience, isStartFromExisting }}
        />
      </FormSection>

      <FormSection>
        <Typography variant="h5">Delivery settings</Typography>
        <Grid container alignItems="center" spacing={2} sx={{ padding: "1rem 1rem 0 0" }}>
          {budgetDisplay}
          {selectPlatformDisplay}
          {deliveryDateDisplay}
          {actblueCodeDisplay}
        </Grid>
      </FormSection>

      <FormSection>
        <Button disabled={isSubmitDisabled} onClick={createOutreachCampaign}>
          Evaluate content
        </Button>
      </FormSection>
    </Box>
  );

  const previewContent = selectedContentGroup ? (
    <>
      <Typography variant="h3">Selected content:</Typography>
      <div className="slider-container" style={{ maxWidth: "500px", margin: "0 5px" }}>
        <CarouselSlider ref={carouselRef} {...CAROUSEL_SETTINGS}>
          {selectedContentGroup.variations &&
            selectedContentGroup.variations.map(v => (
              <VariationPreview isLink={false} key={v.typedId} variation={v} />
            ))}
        </CarouselSlider>
      </div>
    </>
  ) : null;

  return (
    <>
      <Typography variant="h2">Evaluate content</Typography>
      <WarningIfNoActiveAudiencesOrContent alternateContent={<CampaignPricingInfo />} />
      <Box marginTop="1rem">
        <TwoColumnLayoutWithPreview mainContent={mainContent} previewContent={previewContent} />
      </Box>
      {isCreateNewContentModalOpen ? (
        <CreateNewContentModal
          handleNewContentIsReady={handleNewContentIsReady}
          setIsOpen={setIsCreateNewContentModalOpen}
        />
      ) : null}
    </>
  );
};

export default CreateOutreachCampaign;
