import { Box, Grid, Chip, Typography } from "@mui/material";

import { audienceType, countAndNoun, formatDate } from "../../helpers";
import { OneLineTypography } from "../../styles";
import CardItem from "../shared/CardItem";
import Loading from "../shared/Loading";

const AudienceListItem = ({ audience, isInline }) => {
  const { id, name, metadata, createdAt, isArchived, status } = audience;
  const isPending = status === "PENDING";

  let chip;
  if (isArchived) {
    chip = <Chip label="Archived" size="small" />;
  }

  const activeContent = (
    <>
      <Grid container columnSpacing={1}>
        <Grid item xs sx={{ overflow: "hidden" }}>
          <OneLineTypography>{metadata && countAndNoun(metadata.size, "person")}</OneLineTypography>
        </Grid>
        {chip ? (
          <Grid item sx={{ marginLeft: "auto" }}>
            {chip}
          </Grid>
        ) : null}
      </Grid>
    </>
  );

  const pendingContent = (
    <Grid container height="24px">
      <Grid item sx={{ marginRight: "10px" }}>
        <Loading center={false} />
      </Grid>
      <Grid item>
        <Typography sx={{ fontStyle: "italic" }}>Creating audience...</Typography>
      </Grid>
    </Grid>
  );

  return (
    <CardItem {...{ isArchived, isPending, isInline }} linkTo={`/audiences/${id}`}>
      <Box>
        <OneLineTypography
          sx={{ marginBottom: "0.25rem", textDecoration: isInline ? "underline" : "" }}
          title={name}
          variant="h3"
        >
          {name}
        </OneLineTypography>
        <OneLineTypography title={audienceType(audience)}>
          {audienceType(audience)}
        </OneLineTypography>
        <OneLineTypography>{formatDate(createdAt)}</OneLineTypography>
      </Box>
      {isPending ? pendingContent : activeContent}
    </CardItem>
  );
};

export default AudienceListItem;
