import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import InfoBox from "../shared/InfoBox";

const AudienceErrorInfoBox = ({ audience }) => {
  return (
    <InfoBox type="error">
      <Typography>
        Error creating audience: <i>{audience.errorInfoUserFacing || "Unexpected error."}</i>
      </Typography>
      <br />
      <Typography>
        <Link to="/help">Contact us for help</Link>
      </Typography>
    </InfoBox>
  );
};

export default AudienceErrorInfoBox;
