import { SvgIcon } from "@mui/material";

const LightningIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m 17.937968,2.9515 h -7.125365 l -4.750571,9.04853 h 4.750571 L 6.062032,21.0485 17.937968,12.00003 h -4.750591 z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default LightningIcon;
