import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const OneLineTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const TypographyWithSpacing = styled(Typography)`
  margin: 1rem 0;
`;

export const BoxWithSpacing = styled(Box)`
  padding: 0.55rem;
  margin: 1.5rem 0;

  &:first-of-type {
    margin-top: 0.75rem;
  }
  &:last-of-type {
    margin-bottom: 0.75rem;
  }
`;

export const TypographyWithIcon = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
