import { SvgIcon } from "@mui/material";

const MegaphoneIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 9.4612027,7.7511888 15.00142,3.5031315 h 0.923985 V 18.371338 H 15.00142 L 9.4612027,14.123286 m -5.2243438,0 H 8.5372167 V 7.7511888 H 4.2368589 C 3.5516388,7.7511888 2.997,8.3891248 2.997,9.1772309 v 3.5200351 c 0,0.788106 0.5546388,1.42602 1.2398589,1.42602 z m 4.3003578,1.062732 H 4.8437442 v 5.31085 H 8.5372167 Z M 21.003,10.407301 H 17.309526 V 11.47003 H 21.003 Z m -1.599259,-5.043315 -2.341679,2.6932882 0.65288,0.7509157 2.341761,-2.6932882 z m 0.652962,10.391293 -2.341761,-2.693302 -0.65288,0.750922 2.341679,2.693318 z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default MegaphoneIcon;
