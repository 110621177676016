import { Box, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { csvForAudience } from "../../helpers";
import useGetActiveOrg from "../../hooks/useGetActiveOrg";
import useGetAudienceFromParam from "../../hooks/useGetAudienceFromParam";
import useGetCurrentAudiences from "../../hooks/useGetCurrentAudiences";
import InfoBox from "../shared/InfoBox";

import AudienceCsvExportModalContent from "./AudienceCsvExportModalContent";
import InlineCsvUploadInfo from "./InlineCsvUploadInfo";
import SelectAudienceOrUpload from "./SelectAudienceOrUpload";

const EnrichAudience = () => {
  const navigate = useNavigate();
  const { organization } = useGetActiveOrg();

  const audienceFilter = audience => !!audience.uploadedAudienceCsv;
  const validAudiences = useGetCurrentAudiences().filter(audienceFilter);
  const selectedAudience = useGetAudienceFromParam();
  const [uploadedAudienceId, setUploadedAudienceId] = useState();

  const setSelectedAudience = useCallback(
    newSelectedAudience => {
      const selectedAudienceParam = newSelectedAudience ? `/${newSelectedAudience.id}` : "";
      navigate(`/audiences/enrich${selectedAudienceParam}`);
    },
    [navigate],
  );

  let exportFormContent, lowerInfoBox;

  if (selectedAudience) {
    if (selectedAudience.uploadedAudienceCsv && selectedAudience.status === "ACTIVE") {
      exportFormContent = (
        <>
          <Typography maxWidth="sm" sx={{ mt: 2 }} variant="h5">
            Export your enriched list
          </Typography>
          <AudienceCsvExportModalContent audience={selectedAudience} organization={organization} />
        </>
      );

      if (uploadedAudienceId === selectedAudience.id) {
        lowerInfoBox = (
          <InfoBox type="info">
            <InlineCsvUploadInfo
              audience={selectedAudience}
              csv={csvForAudience({ audience: selectedAudience, organization })}
              participle="enriching"
            />
          </InfoBox>
        );
      }
    } else {
      lowerInfoBox = <InfoBox type="error">This isn't a valid CSV-matched audience.</InfoBox>;
    }
  }

  const subtitle = (
    <>
      Note: If you want to export contact information for an audience that's not based on an
      uploaded CSV, visit the details page for that audience in your{" "}
      <Link to="/audiences">audience library</Link> and click "Export to CSV."
    </>
  );

  return (
    <>
      <Typography variant="h2">Enrich an audience</Typography>
      <Box maxWidth="sm">
        <SelectAudienceOrUpload
          {...{
            audienceFilter,
            selectedAudience,
            setSelectedAudience,
            setUploadedAudienceId,
            subtitle,
            validAudiences,
          }}
          flowType="enrich"
        />
        {lowerInfoBox}
      </Box>
      {exportFormContent}
    </>
  );
};

export default EnrichAudience;
