import { gql, useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Button, Grid, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { capitalize, intersection, isEmpty, pick, sortBy } from "lodash";
import pluralize from "pluralize";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { useAppState, useAppStateDispatch } from "../../AppStateContext";
import { FormSection } from "../../common/layout";
import {
  arrayToSentence,
  countAndNoun,
  formatPercentage,
  recursiveCamelToSnake,
  userFriendlyNumber,
} from "../../helpers";
import useAudienceNameField from "../../hooks/useAudienceNameField";
import useGetActiveOrg from "../../hooks/useGetActiveOrg";
import { TypographyWithSpacing } from "../../styles";
import DropdownSelectOne from "../shared/DropdownSelectOne";
import FileDropzone from "../shared/FileDropzone";
import InfoBox from "../shared/InfoBox";
import LoadingExtended from "../shared/LoadingExtended";
import LoadingExtendedFaux from "../shared/LoadingExtendedFaux";
import SharedModal from "../shared/SharedModal";
import SimpleTextButton from "../shared/SimpleTextButton";

const CREATE_AUDIENCE_FOR_UPLOADED_CSV = gql`
  mutation CreateAudienceForUploadedCsv($input: AudienceForUploadedCsvInput!) {
    createAudienceForUploadedCsv(input: $input) {
      id
      status
      uploadedAudienceCsv {
        id
        createdAt
        status
        temporarySignedUrl
      }
    }
  }
`;

const UPDATE_MATCHED_AUDIENCE_PROGRESS = gql`
  mutation UpdateUploadedAudienceCsv($input: MatchedAudienceProgressInput!) {
    updateMatchedAudienceProgress(input: $input) {
      id
    }
  }
`;

const FormGridHeader = styled(Grid)`
  font-weight: bold;
  text-decoration: underline;
`;

const FAUX_MATCHING_STEPS = ["Loading voter file", "Matching your CSV", "Saving match results"];
const PROCESSING_CSV_STEPS = ["Initializing", "Uploading", "Parsing"];

const FLOW_TYPE_WORDS = {
  enrich: {
    gerund: "enriching",
    noun: "enrichment",
  },
  expand: {
    gerund: "expanding",
    noun: "expansion",
  },
};

const REQUIRED_FIELDS_TEXT = (
  <>
    Your list of required fields must include:
    <ol type="a">
      <li>email,</li>
      <li>first name, last name, and at least one other field, or</li>
      <li>full name and at least one other field.</li>
    </ol>
  </>
);

const COLUMN_TYPE_TOOLTIP_TEXT = {
  id: (
    <>
      If you specify an ID column, every row in your CSV must have a unique value present for that
      column.
    </>
  ),
  address: (
    <>
      Street address can't be a required field because matching on address is too unreliable to be
      used as a strict requirement. It can be helpful as an optional matching field, though.
    </>
  ),
};

const MATCHING_FIELD_TOOLTIP_TEXT = {
  dob: (
    <>
      Please ensure that your date of birth values match one of the following formats:
      <ul>
        <li>YYYYMMDD</li>
        <li>YYYY-MM-DD</li>
        <li>YYMMDD</li>
        <li>MMDDYYYY</li>
        <li>MM-DD-YYYY</li>
        <li>MM/DD/YYYY</li>
        <li>MM-DD-YY</li>
        <li>MM/DD/YY</li>
      </ul>
    </>
  ),
};

const textWithTooltip = ({ text, tooltipText }) => (
  <Grid container mt={0} spacing={1}>
    <Grid item>{text}</Grid>
    <Grid item mt={0.25}>
      <Tooltip title={<Typography variant="subtitle2">{tooltipText}</Typography>}>
        <InfoOutlinedIcon fontSize="small" />
      </Tooltip>
    </Grid>
  </Grid>
);

const ErrorsOrWarningsBox = ({ isErrorBox, messagesObject }) => {
  const messages = Object.values(messagesObject);
  let renderedMessages;
  if (messages.length > 1) {
    const preMessage = isErrorBox
      ? "Please resolve the following errors"
      : "We've detected the following potential issues";

    renderedMessages = (
      <>
        {preMessage}:
        <ul>
          {messages.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
        {isErrorBox ? null : (
          <>You can address these possible issues or click "Create audience" to proceed anyway.</>
        )}
      </>
    );
  } else {
    renderedMessages = (
      <>
        {messages[0]}
        {isErrorBox ? null : (
          <>
            <br />
            <br />
            You can address this possible issue or click "Create audience" to proceed anyway.
          </>
        )}
      </>
    );
  }

  return (
    <InfoBox marginTop="-20px" type={isErrorBox ? "error" : "warning"}>
      {renderedMessages}
    </InfoBox>
  );
};

// This should match _sanitize_column_name in uploaded_audience_csv.py (except we don't worry about
// validation here).
const sanitizeColumnName = name => {
  let sanitized = name.toLowerCase();

  // column name can't start with a number
  if (!sanitized.match(/^[_a-z].*/)) {
    sanitized = `_${sanitized}`;
  }

  return sanitized.replaceAll(/[^_a-z0-9]/g, "_");
};

const sentenceTextForFieldSpec = spec => spec.sentence_text || spec.label.toLowerCase();

const COLUMN_TYPE_OPTIONS = ["required", "optional"].map(s => ({ value: s, label: capitalize(s) }));

// How this upload flow works:
// 1. Client asks server to create the initial CSV record (with status READY_FOR_UPLOAD) and
//    audience record (with status PENDING).
// 2. Client gets signed upload URL from server and uploads file directly.
// 3. Client notifies server that upload is complete by passing status READY_FOR_PARSING for CSV.
// 4. Server loads file into BQ and updates CSV status to READY_FOR_COLUMN_SELECTION.
// 5. Client polls for READY_FOR_COLUMN_SELECTION status, asks user to select columns, then passes
//    selections and CSV status ACTIVE to server.
// 6. Server processes column selections and sets CSV status to ACTIVE, then kicks off async
//    matching job, which updates new audience status to ACTIVE when it completes.
// 7. Client polls for ACTIVE status for audience.

const UploadNewAudienceModal = ({ flowType, handleUploadedAudienceIsReady, setIsOpen }) => {
  const { organization } = useGetActiveOrg();
  const { audienceNameField, audienceName, validateAudienceName, resetAudienceName } =
    useAudienceNameField();

  const [errors, setErrors] = useState({});
  const [fieldsCausingErrors, setFieldsCausingErrors] = useState(new Set());
  const [warnings, setWarnings] = useState({});

  const [selectedFile, setSelectedFile] = useState();
  const [pendingAudienceId, setPendingAudienceId] = useState();

  // columnSelections is an object mapping from canonical field name to sanitized column name and
  // whether it's required
  const [columnSelections, setColumnSelections] = useState({});
  const [isColumnSelectionInFlight, setIsColumnSelectionInFlight] = useState(false);
  const [newColumnAddCounter, setNewColumnAddCounter] = useState(0);

  const onRemoveFile = () => {
    setColumnSelections({});
    setPendingAudienceId(null);
    setSelectedFile(null);
    setErrors({});
    setFieldsCausingErrors(new Set());
    resetAudienceName(null);
  };

  const { constants } = useAppState();
  const canonicalFields = constants.CANONICAL_CSV_FIELDS;

  const dispatch = useAppStateDispatch();

  const updateFieldIsRequired = useCallback(
    ({ canonicalField, isRequired }) =>
      setColumnSelections({
        ...columnSelections,
        [canonicalField]: { ...columnSelections[canonicalField], isRequired },
      }),
    [columnSelections],
  );

  const updateColumnMapping = useCallback(
    ({ canonicalField, columnName }) => {
      const newSpec = { ...columnSelections[canonicalField], columnName };
      if (canonicalFields[canonicalField].never_required) {
        newSpec.isRequired = false;
      } else if (canonicalFields[canonicalField].never_optional) {
        newSpec.isRequired = true;
      }

      setColumnSelections({ ...columnSelections, [canonicalField]: newSpec });
    },
    [columnSelections, canonicalFields],
  );

  const deleteColumnMapping = canonicalField => {
    const newSelections = { ...columnSelections };
    delete newSelections[canonicalField];
    setColumnSelections(newSelections);
  };

  const addColumnMapping = canonicalField => {
    // The prepopulation of the column name here would only happen if the user had removed a
    // prepopulated row and then later re-added that field.
    const recognizedSpec = recognizedColumnMappings[canonicalField];
    setColumnSelections({
      ...columnSelections,
      [canonicalField]: { columnName: recognizedSpec && recognizedSpec.columnName },
    });
  };

  let pendingAudience, pendingCsv, columnMetadata;
  if (pendingAudienceId) {
    pendingAudience = organization.audiences.find(a => a.id === pendingAudienceId);
    pendingCsv = organization.uploadedAudienceCsvs.find(
      c => c.id === pendingAudience.uploadedAudienceCsv.id,
    );
    if (pendingCsv.rawColumnMetadata) {
      columnMetadata = pendingCsv.rawColumnMetadata;
    }
  }

  const matchingInitiated =
    pendingCsv && ["COLUMNS_SELECTED", "ACTIVE"].includes(pendingCsv.status);

  // When we show the list of columns in the column selection dropdowns, we want to preserve
  // the ordering from the original CSV.
  const columnNames = useMemo(
    () =>
      columnMetadata
        ? sortBy(Object.entries(columnMetadata), [
            ([columnName, metadata]) => metadata.position,
          ]).map(([columnName, metadata]) => columnName)
        : null,
    [columnMetadata],
  );

  // Try to guess the column mappings to facilitate the column selection step. We'll start with
  // exact string matching of sanitized column names, but we can easily make this a bit smarter.
  const recognizedColumnMappings = useMemo(() => {
    if (!pendingCsv || pendingCsv.status !== "READY_FOR_COLUMN_SELECTION" || !columnNames) {
      return {};
    }

    const mappings = {};
    const sanitizedToOriginal = columnNames.reduce(
      (acc, curr) => ({ ...acc, [sanitizeColumnName(curr)]: curr }),
      {},
    );
    Object.entries(canonicalFields).forEach(([canonicalField, spec]) => {
      if (canonicalField in sanitizedToOriginal) {
        mappings[canonicalField] = {
          columnName: sanitizedToOriginal[canonicalField],
          isRequired: spec.never_required ? false : null,
        };
      }
    });
    return mappings;
  }, [canonicalFields, columnNames, pendingCsv]);

  // This effect uses the recognized mappings to try to prepopulate the column selection form.
  useEffect(() => {
    if (!isEmpty(recognizedColumnMappings) && isEmpty(columnSelections)) {
      // Before we prepopulate, let's drop any columns that are incompatible with other selected
      // columns -- because it would be weird to prepopulate the form with an invalid set of
      // selections.
      const recognizedFields = Object.keys(recognizedColumnMappings);
      const fieldsToPrepopulate = recognizedFields.filter(field => {
        const incompatibles = constants.INCOMPATIBLE_CSV_FIELDS[field];
        return !incompatibles || intersection(incompatibles, recognizedFields).length === 0;
      });
      setColumnSelections(pick(recognizedColumnMappings, fieldsToPrepopulate));
    }
  }, [
    canonicalFields,
    columnSelections,
    constants.INCOMPATIBLE_CSV_FIELDS,
    recognizedColumnMappings,
  ]);

  useEffect(() => {
    if (pendingAudience) {
      if (pendingAudience.status === "ERROR") {
        // We'll toast the error message also.
        setIsOpen(false);
      } else if (pendingAudience.status === "ACTIVE") {
        handleUploadedAudienceIsReady && handleUploadedAudienceIsReady(pendingAudience);
      }
    }
  }, [handleUploadedAudienceIsReady, pendingAudience, setIsOpen]);

  const [doUpdateMatchedAudienceProgressMutation] = useMutation(UPDATE_MATCHED_AUDIENCE_PROGRESS);

  const [doCreateAudienceForUploadedCsvMutation, { loading: creationLoading }] = useMutation(
    CREATE_AUDIENCE_FOR_UPLOADED_CSV,
    {
      onCompleted: data => {
        const audience = data.createAudienceForUploadedCsv;
        const csv = audience.uploadedAudienceCsv;

        // This is where we first add the new audience to our app state, which is necessary so that
        // we can poll for it etc.
        dispatch({
          type: "org-add-or-update-audience",
          organizationId: organization.id,
          audience,
        });

        fetch(csv.temporarySignedUrl, {
          body: selectedFile,
          headers: {
            "Content-Type": selectedFile.type,
          },
          method: "PUT",
        }).then(response => {
          if (response.status === 200) {
            // Tell the server that the file has been uploaded successfully, then start polling
            // so we know when the server is done parsing the file and we're ready to ask the user
            // to select columns.
            doUpdateMatchedAudienceProgressMutation({
              variables: {
                input: {
                  id: audience.id,
                  status: "READY_FOR_PARSING",
                },
              },
              // We don't need an onCompleted since we'll be polling for this audience already.
              // However, note that depending on the timing of the polling and the async matching
              // job, it's possible that from the front end's perspective the CSV status could go
              // directly from `READY_FOR_UPLOAD` to `READY_FOR_COLUMN_SELECTION`, skipping
              // `READY_FOR_PARSING` (even though it will go through that status on the backend).
              // @todo onError?
            });
            setPendingAudienceId(audience.id);
          } else {
            // @todo handle upload error here
          }
        });
      },
    },
  );

  const createUploadedAudienceCsv = file => {
    doCreateAudienceForUploadedCsvMutation({
      variables: {
        input: { organizationId: organization.id, filename: file.name },
      },
    });
  };

  const onSelectFile = file => {
    setSelectedFile(file);
    createUploadedAudienceCsv(file);
  };

  const csvColumnOptions = useMemo(
    () =>
      pendingCsv &&
      columnNames &&
      columnNames.map(columnName => ({
        label: columnName,
        value: columnName,
      })),
    [pendingCsv, columnNames],
  );

  const unusedCanonicalFieldOptions = useMemo(
    () =>
      Object.keys(canonicalFields)
        .filter(canonicalField => !columnSelections[canonicalField])
        .map(canonicalField => ({
          label: canonicalFields[canonicalField].label,
          value: canonicalField,
        })),
    [columnSelections, canonicalFields],
  );

  const isLoadingCsv = creationLoading || !pendingCsv;
  const isUploadingCsv = pendingCsv && pendingCsv.status === "READY_FOR_UPLOAD";
  const isParsingCsv = pendingCsv && pendingCsv.status === "READY_FOR_PARSING";
  const isReadyForColumnnSelection =
    pendingCsv && pendingCsv.status === "READY_FOR_COLUMN_SELECTION";
  const processingCsvStepStatuses = [
    isLoadingCsv,
    isUploadingCsv,
    isParsingCsv,
    isReadyForColumnnSelection,
  ];

  // @todo extract matching UI to separate component
  let columnSelectionDisplay;
  if (isReadyForColumnnSelection) {
    const columnsFormHeader = (
      <>
        <FormGridHeader item xs={2}>
          Matching field
        </FormGridHeader>
        <FormGridHeader item xs={4}>
          CSV column to use
        </FormGridHeader>
        <FormGridHeader item xs={3}>
          Required or optional
        </FormGridHeader>
        <FormGridHeader item xs={2}>
          Presence in CSV
        </FormGridHeader>
      </>
    );

    const columnsFormRows = Object.entries(columnSelections).map(([canonicalField, spec]) => {
      let columnTypeSelect, columnTypeTooltip;
      const neverRequired = canonicalFields[canonicalField].never_required;
      const neverOptional = canonicalFields[canonicalField].never_optional;
      const shouldShowTypeDropdown = !neverRequired && !neverOptional;

      if (shouldShowTypeDropdown) {
        const onChange = columnType =>
          updateFieldIsRequired({ canonicalField, isRequired: columnType === "required" });
        let value;
        if (spec.isRequired) {
          value = "required";
        } else if (spec.isRequired === false) {
          value = "optional";
        }

        columnTypeSelect = (
          <DropdownSelectOne
            data-testid={`${canonicalField}-type-dropdown`}
            onChange={onChange}
            options={COLUMN_TYPE_OPTIONS}
            placeholder="Select an option"
            value={value}
          />
        );
      } else {
        columnTypeSelect = <Typography>{neverOptional ? "Required" : "Optional"}</Typography>;
        columnTypeTooltip = COLUMN_TYPE_TOOLTIP_TEXT[canonicalField];
        if (columnTypeTooltip) {
          columnTypeSelect = textWithTooltip({
            text: columnTypeSelect,
            tooltipText: columnTypeTooltip,
          });
        }
      }

      const populatedText = spec.columnName ? (
        <Typography>
          {formatPercentage({
            value: columnMetadata[spec.columnName].num_populated / pendingCsv.numDataRows,
            sigFig: 2,
          })}{" "}
          of rows
        </Typography>
      ) : null;

      const deleteButton = (
        <SimpleTextButton onClick={() => deleteColumnMapping(canonicalField)}>
          remove
        </SimpleTextButton>
      );

      let fieldLabel = canonicalFields[canonicalField].label;
      const labelTooltip = MATCHING_FIELD_TOOLTIP_TEXT[canonicalField];
      if (labelTooltip) {
        fieldLabel = textWithTooltip({ text: fieldLabel, tooltipText: labelTooltip });
      }

      // Need these to vertically align text with other types of content.
      // I can't figure out why there's a 2px discrepancy between plain text and links!
      const marginAdjustmentText = { marginTop: "8px" };
      const marginAdjustmentLink = { marginTop: "6px" };

      return (
        <Fragment key={canonicalField}>
          <Grid item sx={labelTooltip ? null : marginAdjustmentText} xs={2}>
            <Typography variant={fieldsCausingErrors.has(canonicalField) ? "error" : null}>
              {fieldLabel}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <DropdownSelectOne
              autocomplete
              onChange={columnName => updateColumnMapping({ canonicalField, columnName })}
              options={csvColumnOptions}
              placeholder="Select a column"
              value={spec.columnName}
            />
          </Grid>
          <Grid
            item
            sx={shouldShowTypeDropdown || columnTypeTooltip ? null : marginAdjustmentText}
            xs={3}
          >
            {columnTypeSelect}
          </Grid>
          <Grid item sx={marginAdjustmentText} xs={2}>
            {populatedText}
          </Grid>
          <Grid item sx={marginAdjustmentLink} xs={1}>
            {deleteButton}
          </Grid>
        </Fragment>
      );
    });

    // If they haven't already chosen a column for every single possible field, provide a way
    // to add another field to the list.
    if (Object.keys(columnSelections).length < Object.keys(canonicalFields).length) {
      const onChange = canonicalField => {
        setNewColumnAddCounter(newColumnAddCounter + 1);
        addColumnMapping(canonicalField);
      };

      columnsFormRows.push(
        <Grid item key="add" xs={4}>
          <DropdownSelectOne
            onChange={onChange}
            options={unusedCanonicalFieldOptions}
            placeholder="Add another field"
          />
        </Grid>,
      );
    }

    const validateColumnSelectionForm = () => {
      const newErrors = {};
      const newFieldsCausingErrors = new Set();
      const newWarnings = {};

      if (!validateAudienceName()) {
        newErrors.audienceName = "You must specify a name for your audience.";
      }

      const columnToFields = {};

      Object.entries(columnSelections).forEach(([canonicalField, spec]) => {
        if (spec.columnName) {
          if (columnToFields[spec.columnName]) {
            columnToFields[spec.columnName].push(canonicalField);
          } else {
            columnToFields[spec.columnName] = [canonicalField];
          }
        }

        if (!spec.columnName || (spec.isRequired !== true && spec.isRequired !== false)) {
          newFieldsCausingErrors.add(canonicalField);
        }

        if (spec.columnName) {
          const availability =
            columnMetadata[spec.columnName].num_populated / pendingCsv.numDataRows;

          if (availability < 1) {
            if (canonicalField === "id") {
              newErrors.missingIds = (
                <>
                  You selected {spec.columnName} as your ID field, but not every row in your
                  spreadsheet contains a value in this column.
                </>
              );
            } else if (spec.isRequired) {
              const canonicalSpec = canonicalFields[canonicalField];
              const fieldName = sentenceTextForFieldSpec(canonicalSpec);
              // We have a special case for when it's almost 100% because we're rounding the
              // percentage and it would be weird to say "only 100% of rows" when it's actually a
              // bit lower.
              const renderedPercentage = formatPercentage({ value: availability, sigFig: 2 });
              let problemText;
              if (renderedPercentage === "100%") {
                const numMissing =
                  pendingCsv.numDataRows - columnMetadata[spec.columnName].num_populated;
                problemText = (
                  <>
                    {countAndNoun(numMissing, "row")} in your CSV
                    {numMissing === 1 ? " has" : " have"} no value in this column and won't be
                    eligible for matching.
                  </>
                );
              } else {
                problemText = (
                  <>
                    only {renderedPercentage} of the rows in your CSV have a value in the{" "}
                    {spec.columnName} column. The rows without a value in this column won't be
                    eligible for matching.
                  </>
                );
              }

              newWarnings[`${canonicalField}_populated`] = (
                <>
                  You've specified {fieldName} as required for matching, but {problemText}
                </>
              );
            }
          }
        }
      });

      if (newFieldsCausingErrors.size > 0) {
        newErrors.incompleteSpecs = (
          <>
            For each included field, you must specify a column name and indicate whether the field
            is required or optional for matching. You can click "remove" for any fields that you
            want to exclude from the matching process.
          </>
        );
      }

      let columnsAreUnique = true;
      Object.entries(columnToFields).forEach(([column, canonicalFields]) => {
        if (canonicalFields.length > 1) {
          canonicalFields.forEach(f => {
            newFieldsCausingErrors.add(f);
            columnsAreUnique = false;
          });
        }
      });
      if (!columnsAreUnique) {
        newErrors.duplicateColumns = "You can't assign the same column to multiple fields.";
      }

      // This matches the is_required_set_valid in uploaded_audience_csv.py
      const requiredMatchingFields = Object.entries(columnSelections)
        .filter(([canonicalField, spec]) => spec.isRequired && canonicalField !== "id")
        .map(([canonicalField, spec]) => canonicalField);
      const isRequiredSetValid = constants.VALID_CSV_REQUIRED_FIELD_SETS.some(validSet => {
        return (
          validSet.columns.every(c => requiredMatchingFields.includes(c)) &&
          requiredMatchingFields.length - validSet.columns.length >= validSet.min_additional
        );
      });
      if (!isRequiredSetValid) {
        newErrors.invalidRequiredSet = REQUIRED_FIELDS_TEXT;
      } else if (requiredMatchingFields.length > 4) {
        newWarnings.tooManyRequired = (
          <>
            Requiring more than 3 or 4 columns for matching will increase the quality of your
            matches, but may significantly limit the number of records that can be matched.
          </>
        );
      }

      const fieldsUsed = Object.keys(columnSelections);
      const getSentenceText = field => sentenceTextForFieldSpec(canonicalFields[field]);

      const allIncompatibles = new Set();
      fieldsUsed.forEach(canonicalField => {
        const incompatibles = constants.INCOMPATIBLE_CSV_FIELDS[canonicalField];
        if (incompatibles) {
          incompatibles.forEach(f => allIncompatibles.add(f));
          const invalidFields = intersection(incompatibles, fieldsUsed);
          if (invalidFields.length) {
            newErrors[`incompatibleColumns_${canonicalField}`] = (
              <>
                If you select a column for {getSentenceText(canonicalField)}, you can't also select
                a column for {arrayToSentence(invalidFields.map(getSentenceText), "or")}.
              </>
            );
          }
        }
      });

      // Flag columns that they could use but haven't -- but exclude any that are incompatible with
      // the set of columns that they did choose.
      const overlookedColumns = [];
      const selectedColumns = Object.keys(columnToFields);
      Object.entries(recognizedColumnMappings).forEach(([canonicalField, spec]) => {
        if (!selectedColumns.includes(spec.columnName) && !allIncompatibles.has(canonicalField)) {
          // Also make sure that this column doesn't have an incompatibility relationship with a
          // selected field in the opposite direction.
          const incompatibles = constants.INCOMPATIBLE_CSV_FIELDS[canonicalField];
          if (!incompatibles || !intersection(incompatibles, fieldsUsed)) {
            overlookedColumns.push(spec.columnName);
          }
        }
      });
      if (overlookedColumns.length) {
        const plural = overlookedColumns.length > 1;
        const columnNounPhrase = plural
          ? `multiple columns (${arrayToSentence(overlookedColumns)}) that are`
          : `a column (${overlookedColumns[0]}) that is`;
        newWarnings[`overlookedColumns_${overlookedColumns.join("+")}`] = (
          <>
            Your CSV appears to contain {columnNounPhrase} eligible for matching but you haven't
            elected to use in the matching process. Even if you don't want to require a match on{" "}
            {plural ? "these fields" : "this field"}, adding {plural ? "them" : "it"} as optional
            may increase the number of matches we're able to identify.
          </>
        );
      }

      // Errors always block submission. Warnings block submission at first, but the user can
      // disregard them and submit again.
      const noNewWarnings = Object.keys(newWarnings).every(w => w in warnings);
      const allowSubmission = isEmpty(newErrors) && noNewWarnings;

      // We don't want to update the errors or warnings if allowSubmission is true, because that
      // might cause the warnings/error box to change for a split-second just before the form
      // submits and the page transitions (which would be a little confusing for the user).
      if (!allowSubmission) {
        setErrors(newErrors);
        setFieldsCausingErrors(newFieldsCausingErrors);

        // If there are errors, don't populate the warnings at all. We don't show them in this
        // case anyway -- plus, if the user resolves the errors and clicks the submit button again
        // and the exact same warnings would apply, they'll now get a chance to review them.
        setWarnings(isEmpty(newErrors) ? newWarnings : {});
      }

      return allowSubmission;
    };

    const columnSelectionOnClick = () => {
      if (!validateColumnSelectionForm()) {
        return;
      }
      setIsColumnSelectionInFlight(true);

      doUpdateMatchedAudienceProgressMutation({
        variables: {
          input: {
            audienceName,
            columnSelections: recursiveCamelToSnake(columnSelections),
            id: pendingAudienceId,
            status: "COLUMNS_SELECTED",
          },
        },
        onCompleted: data => {
          // Instead of updating the app state for this audience from here, instead tell our
          // audience polling mechanism in AudienceData to poll for this audience and update
          // it from there. This way we aren't trying to update state for the same audience
          // from two different places, which causes issues.
          dispatch({
            type: "additional-audiences-to-poll-queue-add",
            audienceIdsToPoll: data.updateMatchedAudienceProgress.id,
          });
          // Shouldn't need to toggle this back because we're going to close the modal now anyway.
          // setIsColumnSelectionInFlight(false);
        },
        onError: () => {
          setIsColumnSelectionInFlight(false);
          // If there were errors previously, the most recent call to validateColumnSelectionForm
          // wouldn't have cleared them (because we don't do that when allowSubmission was true).
          setErrors({});
          setFieldsCausingErrors(new Set());
        },
      });
    };

    let errorsOrWarningsSection;
    if (!isEmpty(errors)) {
      errorsOrWarningsSection = <ErrorsOrWarningsBox isErrorBox messagesObject={errors} />;
    } else if (!isEmpty(warnings)) {
      errorsOrWarningsSection = (
        <ErrorsOrWarningsBox isErrorBox={false} messagesObject={warnings} />
      );
    }

    columnSelectionDisplay = (
      <>
        <FormSection maxWidth="md">
          Your uploaded CSV contains <b>{userFriendlyNumber(pendingCsv.numDataRows)}</b>{" "}
          {pluralize("row", pendingCsv.numDataRows)} and{" "}
          <b>{userFriendlyNumber(columnNames.length)}</b> {pluralize("column", columnNames.length)}.
        </FormSection>
        <FormSection maxWidth="md">
          Please indicate which columns in your CSV correspond to fields that are eligible for
          inclusion in the matching process.
          <ul>
            <li>{REQUIRED_FIELDS_TEXT}</li>
            <li>
              You can also specify any number of additional optional fields that will be used to
              improve your matching results.
            </li>
            <li>
              If your uploaded CSV has an ID column that uniquely identifies each row, you can
              specify it for tracking purposes. If you don't specify an ID column, we'll identify
              the records in your CSV using row numbers. You may want to use these IDs to link your
              matched audience back to your original CSV data.
            </li>
          </ul>
        </FormSection>
        <FormSection>
          <Grid container maxWidth={850} spacing={2}>
            {columnsFormHeader}
            {columnsFormRows}
          </Grid>
        </FormSection>
        <FormSection maxWidth="xs">{audienceNameField}</FormSection>
        {errorsOrWarningsSection}
        <FormSection>
          <Button
            disabled={isColumnSelectionInFlight}
            onClick={columnSelectionOnClick}
            type="submit"
          >
            Create audience
          </Button>
        </FormSection>
      </>
    );
  }

  const intro = (
    <FormSection>
      <b>Tips:</b>
      <ul>
        <li>Your CSV must contain a header row.</li>
        <li>{REQUIRED_FIELDS_TEXT}</li>
        <li>
          If you have more information in your CSV (e.g. phone numbers or birthdates), you can also
          add these fields as required or optional for matching.
        </li>
        <li>
          Including more than 3 or 4 fields as required for matching can result in a low number of
          matches. Including more fields as optional for matching, however, is likely to improve the
          volume and accuracy of your matches.
        </li>
        <li>
          After you upload your file, you'll have the opportunity to indicate which field is in each
          column of your CSV and which fields should be required vs. optional for matching.
        </li>
      </ul>
    </FormSection>
  );

  let formContent, closeConfirmationText;
  if (!selectedFile) {
    formContent = intro;
  } else if (processingCsvStepStatuses.some(Boolean)) {
    const completedStep = processingCsvStepStatuses.lastIndexOf(true) - 1;

    formContent = (
      <LoadingExtended
        completedStep={completedStep}
        contentToRenderWhenComplete={columnSelectionDisplay}
        steps={PROCESSING_CSV_STEPS}
        title="Processing your CSV..."
      />
    );

    closeConfirmationText = "Are you sure that you want to discard your uploaded CSV?";
  } else if (matchingInitiated) {
    const flowWords = FLOW_TYPE_WORDS[flowType];

    // Now we wait. Once the audience is no longer pending (i.e. it succeeded or failed), we'll call
    // handleUploadedAudienceIsReady.
    formContent = (
      <>
        <LoadingExtendedFaux
          isLoading={true}
          stepDurationSeconds={10}
          steps={FAUX_MATCHING_STEPS}
          title="Matching in progress. This may take a few minutes..."
        />
        <TypographyWithSpacing>
          If you don't want to wait for the matching to complete, you can navigate away from this
          page and we'll notify you when your audience has been activated and is ready for{" "}
          {flowWords.noun}.
        </TypographyWithSpacing>
      </>
    );

    closeConfirmationText =
      `Are you sure that you want to stop ${flowWords.gerund} this list?\n\nNote that we'll ` +
      "continue processing your new audience in the background in case you want to use it later.";
  }

  let fileChooser;
  if (selectedFile) {
    let removeButton;
    if (!matchingInitiated) {
      removeButton = (
        <Box sx={{ paddingTop: "7px" }}>
          <SimpleTextButton onClick={onRemoveFile}>change</SimpleTextButton>
        </Box>
      );
    }
    fileChooser = (
      <Stack direction="row" spacing={2}>
        <span style={{ fontSize: "1.5rem" }}>{selectedFile.name}</span>
        {removeButton}
      </Stack>
    );
  } else {
    fileChooser = <FileDropzone fileType="CSV" onSelect={onSelectFile} />;
  }

  return (
    <SharedModal
      isFullScreen
      closeConfirmationText={closeConfirmationText}
      isOpen={true}
      setIsOpen={setIsOpen}
      title="Upload a CSV"
    >
      <FormSection>{fileChooser}</FormSection>
      {formContent}
    </SharedModal>
  );
};

export default UploadNewAudienceModal;
