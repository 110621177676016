import { sortBy } from "lodash";
import { useState } from "react";
import { Link } from "react-router-dom";

import { countAndNoun, formatDateTime } from "../../helpers";
import SharedModal from "../shared/SharedModal";
import TextLabel from "../shared/TextLabel";

const nameForCampaign = c => {
  return `${formatDateTime(c.createdAt)}`;
};

const CampaignsUsedIn = ({ audienceOrContentGroup }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const campaigns = audienceOrContentGroup.outreachCampaigns;
  if (!campaigns || !campaigns.length) {
    return null;
  }

  let content;
  if (campaigns.length > 1) {
    content = (
      <>
        <Link onClick={() => setIsModalOpen(true)}>
          {countAndNoun(campaigns.length, "evaluation")}
        </Link>
        <SharedModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="Content evaluations">
          <ul style={{ margin: 0 }}>
            {sortBy(campaigns, c => c.createdAt)
              .reverse()
              .map((c, i) => (
                <li key={`campaign-${c.id}`}>
                  <Link to={`/evaluations/${c.id}`}>{nameForCampaign(c)}</Link>
                </li>
              ))}
          </ul>
        </SharedModal>
      </>
    );
  } else {
    content = <Link to={`/evaluations/${campaigns[0].id}`}>{nameForCampaign(campaigns[0])}</Link>;
  }

  return (
    <>
      <TextLabel>Used in: </TextLabel>
      {content}
    </>
  );
};

export default CampaignsUsedIn;
