import { SvgIcon } from "@mui/material";

const SunIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 13.125081,5.3449428 V 3.000002 H 10.87491 v 2.3449408 c 0.366227,-0.061602 0.741255,-0.09545 1.125085,-0.09545 0.383829,0 0.758857,0.033848 1.125086,0.09545 z"
          fill="currentColor"
        />
        <path
          d="M 8.0899915,6.4991368 6.4314723,4.8406176 4.8406477,6.4314421 6.499167,8.0899616 C 6.9371513,7.4746168 7.475324,6.9371212 8.0899915,6.4991368 Z"
          fill="currentColor"
        />
        <path
          d="M 17.500864,8.0899616 19.159383,6.4314421 17.568558,4.8406176 15.910039,6.4991368 c 0.615344,0.4379844 1.15284,0.976157 1.590825,1.5908248 z"
          fill="currentColor"
        />
        <path
          d="m 5.2494896,11.999992 c 0,-0.38383 0.033861,-0.758857 0.095463,-1.125085 H 2.9999998 v 2.250172 h 2.3449529 c -0.061602,-0.36623 -0.095463,-0.741257 -0.095463,-1.125087 z"
          fill="currentColor"
        />
        <path
          d="m 15.910039,17.500861 1.658519,1.658519 1.590825,-1.590824 -1.658519,-1.65852 c -0.437985,0.615345 -0.976158,1.152841 -1.590825,1.590825 z"
          fill="currentColor"
        />
        <path
          d="m 6.499167,15.910036 -1.6585193,1.65852 1.5908246,1.590824 1.6585193,-1.658519 C 7.474647,17.062877 6.9371513,16.524704 6.499167,15.910036 Z"
          fill="currentColor"
        />
        <path
          d="m 18.655056,10.874907 c 0.06161,0.366228 0.09545,0.741255 0.09545,1.125085 0,0.38383 -0.03384,0.758857 -0.09545,1.125087 H 21 v -2.250172 z"
          fill="currentColor"
        />
        <path
          d="m 10.87491,18.655054 v 2.344944 h 2.250171 v -2.344944 c -0.366229,0.06161 -0.741257,0.09545 -1.125086,0.09545 -0.38383,0 -0.758858,-0.03384 -1.125085,-0.09545 z"
          fill="currentColor"
        />
        <path
          d="m 11.999995,16.500334 c 2.485478,0 4.500341,-2.014864 4.500341,-4.500342 0,-2.4854704 -2.014863,-4.5003416 -4.500341,-4.5003416 -2.485464,0 -4.5003482,2.0148712 -4.5003482,4.5003416 0,2.485478 2.0148842,4.500342 4.5003482,4.500342 z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default SunIcon;
