import { gql, useMutation } from "@apollo/client";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CircleIcon from "@mui/icons-material/Circle";
import LogoutIcon from "@mui/icons-material/Logout";
import MmsIcon from "@mui/icons-material/Mms";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PersonIcon from "@mui/icons-material/Person";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
// import SearchIcon from "@mui/icons-material/Search";
// import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
// import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  AppBar,
  Button,
} from "@mui/material";
import { flatten } from "lodash";
import { forwardRef } from "react";
import { NavLink, Link, useLocation, matchPath } from "react-router-dom";

import { useAppState } from "../AppStateContext";
import { COLORS } from "../colors";
import { TOP_NAV_HEIGHT } from "../constants";
import { countAndNoun } from "../helpers";
import useGetActiveOrg from "../hooks/useGetActiveOrg";

import OrgPicker from "./OrgPicker";
import MatchbookLogoWithText from "./svgs/MatchbookLogoWithText";
import LightningIcon from "./svgs/icons/LightningIcon";
import MegaphoneIcon from "./svgs/icons/MegaphoneIcon";
import StarIcon from "./svgs/icons/StarIcon";

const LOGOUT = gql`
  mutation {
    logout
  }
`;

const DRAWER_WIDTH = 190;
const NAV_HEADER_ICON_SIZE = "16px";

const MAIN_NAV_SECTIONS = [
  {
    header: "Audiences",
    url: "/audiences",
    color: COLORS.purple,
    icon: StarIcon,
    items: [
      { name: "Create", url: "/audiences/create" },
      { name: "Enrich", url: "/audiences/enrich" },
      { name: "Expand", url: "/audiences/expand" },
      { name: "Predict", url: "/audiences/predict" },
    ],
  },
  {
    header: "Content",
    url: "/content",
    icon: MegaphoneIcon,
    color: COLORS.teal,
    items: [
      { name: "Evaluate", url: "/evaluations/new" },
      { name: "Generate", url: "/content/generate" },
      { name: "Improve", url: "/content/improve" },
    ],
  },
  {
    header: "Trends",
    icon: LightningIcon,
    color: COLORS.magenta,
    items: [{ name: "Forecast", url: "/trends/forecast" }],
  },
];
const MAIN_NAV_URLS = flatten(
  MAIN_NAV_SECTIONS.map(section => section.items.map(item => item.url)),
);

const SECONDARY_NAV_ITEMS = [
  { name: "Audience library", url: "/audiences", icon: PermContactCalendarIcon },
  { name: "Content library", url: "/content", icon: MmsIcon },
  { name: "Evaluation library", url: "/evaluations", icon: AutoFixHighIcon },
  // { name: "Experiments", url: "/experiments", icon: SearchIcon },
  { name: "Account", url: "/account", icon: PersonIcon },
  { name: "Help", url: "/help", icon: QuestionAnswerIcon },
];

const MatchbookNavLink = forwardRef(({ children, className, isLibraryPath, ...rest }, ref) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const libraryRoutePattern = `${rest.to}/*`;

  const anyMainNavUrlsMatch = MAIN_NAV_URLS.find(url => matchPath(`${url}/*`, currentPath));
  const isActiveLibraryPath =
    !anyMainNavUrlsMatch && isLibraryPath && rest.to && matchPath(libraryRoutePattern, currentPath);

  return (
    <Box sx={{ "& .active span": { fontWeight: "bold" } }}>
      <NavLink
        ref={ref}
        {...rest}
        className={({ isActive }) => {
          return (isActive || isActiveLibraryPath ? "active " : "") + className;
        }}
      >
        {children}
      </NavLink>
    </Box>
  );
});
MatchbookNavLink.displayName = "MatchbookNavLink";

const LeftNav = () => {
  return (
    <Drawer
      anchor="left"
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
    >
      <Toolbar
        disableGutters
        sx={{
          flexDirection: "column",
          alignItems: "center",
          padding: "1rem 1.5rem 0 1.5rem",
          marginBottom: "1rem",
        }}
      >
        <Box>
          <Link to="/">
            <MatchbookLogoWithText width="100%" />
          </Link>
        </Box>
        <Typography color={COLORS.purple} variant="body2">
          ({process.env.REACT_APP_ENV === "staging" ? "STAGING" : "beta"})
        </Typography>
      </Toolbar>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box>
          {MAIN_NAV_SECTIONS.map((section, index) => (
            <Box key={index} sx={{ marginBottom: "1rem" }}>
              <Typography
                sx={{
                  backgroundColor: section.color,
                  color: COLORS.white,
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  padding: "3px 0 3px 20px",
                }}
              >
                <section.icon sx={{ fontSize: NAV_HEADER_ICON_SIZE, marginRight: "10px" }} />{" "}
                <Link style={{ textDecoration: "none", color: COLORS.white }} to={section.url}>
                  {section.header}
                </Link>
              </Typography>
              <List>
                {section.items.map((item, itemIndex) => (
                  <ListItem
                    disablePadding
                    component={MatchbookNavLink}
                    key={itemIndex}
                    to={item.url}
                  >
                    <ListItemButton sx={{ padding: "1px 0 1px 20px" }}>
                      <ListItemIcon
                        sx={{
                          marginRight: "10px",
                          // Match the spacing and alignment of the icon in the header (so that
                          // everything lines up nicely), while making the actual icon size for the
                          // individual nav items smaller (otherwise the circle looks huge).
                          minWidth: NAV_HEADER_ICON_SIZE,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircleIcon sx={{ fontSize: "8px", color: COLORS.black }} />
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>
        <Box sx={{ margin: "auto 0 2rem" }}>
          {SECONDARY_NAV_ITEMS.map((item, itemIndex) => (
            <ListItem
              disablePadding
              end
              isLibraryPath
              component={MatchbookNavLink}
              key={itemIndex}
              to={item.url}
            >
              <ListItemButton sx={{ padding: "1px 0 1px 20px" }}>
                <ListItemIcon sx={{ minWidth: "16px", marginRight: "10px" }}>
                  <item.icon sx={{ fontSize: "14px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{
                    sx: { fontSize: "small" },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
};

const TopNav = () => {
  const { organization } = useGetActiveOrg();
  const { user } = useAppState();
  const [doLogoutMutation] = useMutation(LOGOUT);

  const logout = () => {
    doLogoutMutation({
      onCompleted: () => {
        window.location.reload();
      },
    });
  };

  const creditDisplay = organization.isExemptFromPayments ? null : (
    <>
      : <Link to="/credits/purchase">{countAndNoun(organization.numCredits, "credit")}</Link>
    </>
  );

  return (
    <AppBar
      position="absolute"
      sx={{
        backgroundColor: COLORS.white,
        color: COLORS.black,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: `${DRAWER_WIDTH}px`,
        height: `${TOP_NAV_HEIGHT}px`,
      }}
    >
      <Toolbar>
        <Typography noWrap sx={{ flexGrow: 1 }}>
          <strong>{organization.name}</strong>
          {creditDisplay}
        </Typography>
        {user.isStaff ? <OrgPicker /> : null}
        <Button onClick={logout} startIcon={<LogoutIcon />} variant="text">
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

const Nav = () => {
  return (
    <>
      <TopNav />
      <LeftNav />
    </>
  );
};

export default Nav;
