import { Typography } from "@mui/material";
import pluralize from "pluralize";
import { useState } from "react";
import { Link } from "react-router-dom";

import { countAndNoun } from "../../helpers";
import useAudienceFiltersSummaries from "../../hooks/useAudienceFiltersSummaries";
import Loading from "../shared/Loading";
import SharedModal from "../shared/SharedModal";

const FiltersSummaries = ({ audience, includeWasOrWere = false }) => {
  const [isFiltersSummaryModalOpen, setIsFiltersSummaryModalOpen] = useState(false);
  const { filtersSummariesList, isFiltersSummariesLoading } = useAudienceFiltersSummaries(
    audience && audience.filters,
  );

  let lookalikeWithFiltersContent;
  if (audience.audienceLookalikeCreatedFrom) {
    lookalikeWithFiltersContent = " for lookalike creation";
  }

  return (
    <>
      <Link onClick={() => setIsFiltersSummaryModalOpen(true)}>
        {countAndNoun(filtersSummariesList.length, "filter")}
      </Link>
      {includeWasOrWere ? ` ${pluralize("was", filtersSummariesList.length)}` : ""}
      <SharedModal
        isOpen={isFiltersSummaryModalOpen}
        setIsOpen={setIsFiltersSummaryModalOpen}
        title={`Filters used${lookalikeWithFiltersContent}`}
      >
        {isFiltersSummariesLoading ? (
          <Loading />
        ) : (
          filtersSummariesList.map(summary => <Typography key={summary}>{summary}</Typography>)
        )}
      </SharedModal>
    </>
  );
};

export default FiltersSummaries;
