import SharedModal from "../shared/SharedModal";

import CsvMatchResults from "./CsvMatchResults";

const CsvMatchSummaryModal = ({ audience, csv, isOpen, setIsOpen }) => (
  <SharedModal {...{ isOpen, setIsOpen }} title="CSV match results" width={800}>
    <CsvMatchResults csv={csv} metadata={audience.uploadedAudienceCsvMatchMetadata} />
  </SharedModal>
);

export default CsvMatchSummaryModal;
