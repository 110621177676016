import { gql, useMutation } from "@apollo/client";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { maxBy } from "lodash";
import pluralize from "pluralize";
import { useEffect, useState } from "react";

import { useAppState, useAppStateDispatch } from "../../AppStateContext";
import { countAndNoun, userFriendlyNumber } from "../../helpers";
import useGetActiveOrg from "../../hooks/useGetActiveOrg";
import { TypographyWithSpacing } from "../../styles";
import ButtonModal from "../shared/ButtonModal";
import ExternalLink from "../shared/ExternalLink";
import InfoBox from "../shared/InfoBox";
import Loading from "../shared/Loading";

const MIN_AUDIENCE_SIZE_FOR_UPLOAD_TIME_WARNING = 50 * 1000;

const CREATE_FACEBOOK_CUSTOM_AUDIENCE_EXPORT = gql`
  mutation CreateFacebookCustomAudienceExport(
    $audienceId: ID!
    $name: String!
    $facebookAdAccountId: String!
    $facebookAdAccountName: String!
  ) {
    createFacebookCustomAudienceExport(
      audienceId: $audienceId
      name: $name
      facebookAdAccountId: $facebookAdAccountId
      facebookAdAccountName: $facebookAdAccountName
    ) {
      id
      errorInfo
      status
      purchaseCostBreakdown
      totalCost
    }
  }
`;

// TODO move this to useMakeAudiencePurchase?
const PURCHASE_FACEBOOK_CUSTOM_AUDIENCE_EXPORT = gql`
  mutation PurchaseFacebookCustomAudienceExport($pk: ID!) {
    purchaseFacebookCustomAudienceExport(pk: $pk) {
      id
      status
    }
  }
`;

const userHasPermission = adAccount =>
  adAccount.permissions && adAccount.permissions.includes("ADVERTISE");

const getErrorMsg = adAccount => {
  if (!userHasPermission(adAccount)) {
    return (
      <>
        Sorry, we're unable to create a custom audience for the "{adAccount.name}" ad account
        because your Facebook user account doesn't have the required permission. Please ask someone
        with admin access to go to the ad account's settings and grant you either the "Manage
        campaigns" or "Manage ad account" permission. Once you have proper access, click the Retry
        button.
      </>
    );
  } else if (!adAccount.hasBusiness) {
    return (
      <>
        Your ad account "{adAccount.name}" must be associated with a Facebook Business in order to
        use custom audiences.
        <br />
        <br />
        If you already have a Facebook Business, you'll need to make sure that it's linked to your
        Facebook ad account. Check out Facebook's{" "}
        <ExternalLink to="https://www.facebook.com/business/help/910137316041095">
          instructions
        </ExternalLink>{" "}
        for linking your ad account.
        <br />
        <br />
        Once you've set up Facebook Business Manager and added your ad account to it, click the
        Retry button.
      </>
    );
  } else if (!adAccount.hasAcceptedTos) {
    const fbTosUrl =
      "https://business.facebook.com/ads/manage/customaudiences/tos/?act=" + adAccount.id;

    return (
      <>
        Before you can create a custom audience for this ad account, you need to read and accept
        Facebook's{" "}
        <ExternalLink to={fbTosUrl}>Terms of Service agreement for custom audiences</ExternalLink>.
        <br />
        <br />
        Once you've completed this step, click the Retry button.
      </>
    );
  } else {
    return null;
  }
};

const adsManagerUrl = accountId =>
  `https://business.facebook.com/adsmanager/audiences?act=${accountId}`;

const AudienceFacebookExportModal = ({
  adAccounts,
  audience,
  isOpen,
  setIsOpen,
  refetchAdAccounts,
  isFetchAdAccountsLoading,
}) => {
  const dispatch = useAppStateDispatch();
  const { constants } = useAppState();
  const { AUDIENCE_FIELD_PRICING } = constants;

  const { organization } = useGetActiveOrg();

  const [selectedAdAccountId, setSelectedAdAccountId] = useState();
  const [customAudienceName, setCustomAudienceName] = useState(audience.name);
  const [facebookCustomAudienceExportId, setFacebookCustomAudienceExportId] = useState();
  const [hasSkippedExistingAudienceMessage, setHasSkippedExistingAudienceMessage] = useState(false);

  const facebookCustomAudienceExport = audience.facebookCustomAudienceExports.find(
    fcae => fcae.id === facebookCustomAudienceExportId,
  );

  // If the user hits the Back button, this behaves differently depending on which step they're at.
  const reset = ({ shouldResetEverything = true } = {}) => {
    const exportWasSet = !!facebookCustomAudienceExportId;
    setFacebookCustomAudienceExportId(null);

    if (!exportWasSet || shouldResetEverything) {
      setCustomAudienceName(audience.name);
      setSelectedAdAccountId(null);
    }

    if (shouldResetEverything) {
      setHasSkippedExistingAudienceMessage(false);
    }
  };

  const updateExport = facebookCustomAudienceExport => {
    dispatch({
      type: "audience-add-or-update-facebook-custom-audience-export",
      facebookCustomAudienceExport,
      organizationId: organization.id,
      audienceId: audience.id,
    });
  };

  const [createFacebookCustomAudience, { loading: creationLoading }] = useMutation(
    CREATE_FACEBOOK_CUSTOM_AUDIENCE_EXPORT,
    {
      onCompleted: data => {
        const facebookCustomAudienceExport = data.createFacebookCustomAudienceExport;
        setFacebookCustomAudienceExportId(facebookCustomAudienceExport.id);
        updateExport(facebookCustomAudienceExport);
      },
    },
  );

  const createCustomAudience = () => {
    createFacebookCustomAudience({
      variables: {
        audienceId: audience.id,
        name: customAudienceName,
        facebookAdAccountId: selectedAdAccountId,
        facebookAdAccountName: selectedAdAccount.name,
      },
    });
  };

  const [purchaseFacebookCustomAudience, { loading: purchaseLoading }] = useMutation(
    PURCHASE_FACEBOOK_CUSTOM_AUDIENCE_EXPORT,
    {
      onCompleted: data => updateExport(data.purchaseFacebookCustomAudienceExport),
    },
  );

  const purchaseCustomAudience = () => {
    purchaseFacebookCustomAudience({
      variables: {
        pk: facebookCustomAudienceExportId,
      },
    });
  };

  useEffect(() => {
    if (adAccounts && adAccounts.length === 1) {
      setSelectedAdAccountId(adAccounts[0].id);
    }
  }, [adAccounts]);

  const selectedAdAccount =
    selectedAdAccountId && adAccounts.find(a => a.id === selectedAdAccountId);

  let content, onConfirm, confirmText, errorMsg, altText, onAlt;

  const existingActiveExports = audience.facebookCustomAudienceExports.filter(
    fcae => fcae.status === "ACTIVE",
  );

  if ((!adAccounts && isFetchAdAccountsLoading) || creationLoading) {
    content = <Loading />;
  } else if (facebookCustomAudienceExport) {
    if (purchaseLoading || facebookCustomAudienceExport.status === "IN_PROGRESS") {
      const timeEstimate =
        audience.metadata.size > MIN_AUDIENCE_SIZE_FOR_UPLOAD_TIME_WARNING
          ? "This may take a few minutes."
          : "Please wait for the operation to complete.";

      content = (
        <>
          <Typography>
            We're currently uploading your custom audience to Facebook. {timeEstimate}
          </Typography>
          <Loading />
        </>
      );
    } else if (facebookCustomAudienceExport.status === "REQUIRES_PAYMENT") {
      const cost = facebookCustomAudienceExport.totalCost;
      const { count_total_available: totalAvailable, count_requiring_payment: countUnpaid } =
        facebookCustomAudienceExport.purchaseCostBreakdown;
      const countAlreadyPaid = totalAvailable - countUnpaid;

      let costExplanation;
      if (organization.numCredits >= cost) {
        confirmText = "Create custom audience";
        onConfirm = purchaseCustomAudience;
        if (cost === 0) {
          costExplanation = (
            <>
              There's no cost to generate this Facebook custom audience, however, because your
              organization has already uploaded all of these voters to other custom audiences.
            </>
          );
        } else if (countAlreadyPaid) {
          costExplanation = (
            <>
              The cost of this custom audience is reduced because your organization has already paid
              to upload {userFriendlyNumber(countAlreadyPaid)} of these{" "}
              {pluralize("voter", countAlreadyPaid)} to other custom audiences this year.
            </>
          );
        }
      } else {
        // TODO add PurchaseCreditsDirectly here
        costExplanation = <>Your organization doesn't have enough credits to make this purchase.</>;
      }
      const audienceSummary = (
        <>
          <b>Custom audience name:</b> {customAudienceName}
          <br />
          <b>Facebook ad account:</b> {selectedAdAccount.name} ({selectedAdAccountId})
          <br />
          <b>Voter records to upload:</b> {userFriendlyNumber(totalAvailable)}
          <br />
          <b>Credits required:</b> {userFriendlyNumber(cost)}
          <br />
        </>
      );

      content = (
        <Typography>
          {audienceSummary}
          <br />
          Matchbook charges{" "}
          {countAndNoun(AUDIENCE_FIELD_PRICING.facebook_custom_audience.price, "credit")} per voter
          record to create a Facebook custom audience.
          <TypographyWithSpacing>{costExplanation}</TypographyWithSpacing>
          Note that the match rate for Facebook custom audiences based on uploaded data can vary
          significantly. Your Facebook audience is likely to include only a subset of the voters
          that are in this Matchbook audience.
        </Typography>
      );
    } else if (facebookCustomAudienceExport.status === "ERROR") {
      content = (
        <InfoBox type="error">
          Sorry, we ran into an error while generating your custom audience.
          <br />
          <br />
          {facebookCustomAudienceExport.errorInfo}
        </InfoBox>
      );
    } else {
      onConfirm = () => {
        setIsOpen(false);
        reset();
      };
      confirmText = "Done";
      const url = adsManagerUrl(selectedAdAccountId);
      content = (
        <Typography>
          Your audience has been uploaded to Facebook. Visit{" "}
          <ExternalLink to={url}>Facebook Ads Manager</ExternalLink> to review all of the custom
          audiences in the "{selectedAdAccount.name}" ad account.
        </Typography>
      );
    }
  } else if (existingActiveExports.length && !hasSkippedExistingAudienceMessage) {
    onConfirm = () => {
      refetchAdAccounts();
      setHasSkippedExistingAudienceMessage(true);
    };
    confirmText = "Export again";
    if (existingActiveExports.length > 1) {
      const renderedExports = existingActiveExports.map(fcae => (
        <li key={fcae.id}>
          {fcae.name} ({fcae.facebookAdAccountName})
        </li>
      ));
      // In theory they could have exported to multiple ad accounts, but it's easy enough for them
      // to switch among them on the FB end, so let's just link to the most recent one.
      const url = adsManagerUrl(maxBy(existingActiveExports, "createdAt").facebookAdAccountId);
      content = (
        <>
          <Typography>
            You've already exported this audience to the following Facebook custom audiences:
          </Typography>
          <ul>{renderedExports}</ul>
          <Typography>
            Visit <ExternalLink to={url}>Facebook Ads Manager</ExternalLink> to see your audiences.
          </Typography>
        </>
      );
    } else {
      const fcae = existingActiveExports[0];
      const url = adsManagerUrl(fcae.facebookAdAccountId);
      content = (
        <>
          <TypographyWithSpacing>
            You've already exported this audience to a Facebook custom audience called "{fcae.name}"
            in the ad account "{fcae.facebookAdAccountName}."
          </TypographyWithSpacing>
          <TypographyWithSpacing>
            You can view it in <ExternalLink to={url}>Facebook Ads Manager</ExternalLink>.
          </TypographyWithSpacing>
        </>
      );
    }
  } else if (selectedAdAccount) {
    errorMsg = getErrorMsg(selectedAdAccount);
    if (errorMsg) {
      altText = "Retry";
      onAlt = refetchAdAccounts;
      content = <InfoBox type="error">{errorMsg}</InfoBox>;
    } else {
      onConfirm = createCustomAudience;
      confirmText = "Review price";

      // TODO: this TextField is very similar to the one in useAudienceNameField
      content = (
        <FormControl>
          <Stack spacing={3}>
            <Typography>
              Your custom audience will be created in the ad account "{selectedAdAccount.name}."
            </Typography>
            <TextField
              autoComplete="off"
              inputProps={{ autoComplete: "off" }}
              label="Name for your Facebook custom audience"
              margin="dense"
              name="custom-audience-name"
              onChange={e => setCustomAudienceName(e.target.value)}
              size="small"
              value={customAudienceName}
            />
          </Stack>
        </FormControl>
      );
    }
  } else if (!adAccounts) {
    content = <Loading />;
  } else if (adAccounts.length === 0) {
    content = (
      <InfoBox type="error">
        We can't create a custom audience because your Facebook user account doesn't appear to have
        access to any ad accounts.
      </InfoBox>
    );
  } else {
    const radios = adAccounts.map(adAccount => (
      <FormControlLabel
        control={<Radio />}
        key={adAccount.id}
        label={adAccount.name}
        value={adAccount.id}
      />
    ));

    content = (
      <FormControl>
        Which Facebook ad account is this custom audience intended for?
        <RadioGroup
          aria-labelledby="select-ad-account"
          onChange={e => setSelectedAdAccountId(e.target.value)}
          value={selectedAdAccountId}
        >
          {radios}
        </RadioGroup>
      </FormControl>
    );
  }

  // We don't show the Back button on the first step or at the end (when they're waiting for the
  // upload to complete or we're reporting that it's finished). We also don't show it if the user
  // is on the second step but they only have one ad account.

  const showBackButton =
    !!selectedAdAccountId &&
    !(facebookCustomAudienceExport && facebookCustomAudienceExport.status !== "REQUIRES_PAYMENT") &&
    !(adAccounts.length === 1 && !facebookCustomAudienceExport);

  return (
    <ButtonModal
      {...{ isOpen, setIsOpen }}
      altDisabled={isFetchAdAccountsLoading}
      altText={altText}
      cancelText={<span>&larr; &nbsp;Back</span>}
      confirmDisabled={!customAudienceName || creationLoading}
      confirmText={confirmText}
      onAlt={onAlt}
      onCancel={() => reset({ shouldResetEverything: false })}
      onClose={reset}
      onConfirm={onConfirm}
      showAlt={!!altText}
      showCancel={showBackButton}
      showConfirm={!!confirmText}
      title="Configure your Facebook audience export"
      width={600}
    >
      {content}
    </ButtonModal>
  );
};

export default AudienceFacebookExportModal;
