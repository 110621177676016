import { Button, Grid } from "@mui/material";
import { Box, Stack } from "@mui/system";

import SharedModal from "./SharedModal";

const ButtonModal = ({
  cancelText = "Cancel",
  children = "Are you sure you want to take this action?",
  confirmDisabled = false,
  altDisabled = false,
  confirmText = "OK",
  altText,
  isOpen = false,
  onCancel,
  onConfirm,
  onAlt,
  setIsOpen,
  showCancel = true,
  showConfirm = true,
  showAlt = false,
  title = "Please confirm",
  ...rest
}) => {
  const isAnyButtonShown = showCancel || showAlt || showConfirm;
  const cancelButton = showCancel ? (
    <Box flexGrow={1}>
      <Button color="secondary" onClick={onCancel} variant="outlined">
        {cancelText}
      </Button>
    </Box>
  ) : null;

  const altButton = showAlt ? (
    <Box>
      <Button color="secondary" disabled={altDisabled} onClick={onAlt}>
        {altText}
      </Button>
    </Box>
  ) : null;

  const confirmButton = showConfirm ? (
    <Box>
      <Button color="primary" disabled={confirmDisabled} onClick={onConfirm}>
        {confirmText}
      </Button>
    </Box>
  ) : null;

  return (
    <SharedModal {...rest} isOpen={isOpen} setIsOpen={setIsOpen} title={title}>
      <Stack>
        <div>{children}</div>
        {isAnyButtonShown ? (
          <Grid container spacing={0} sx={{ marginTop: "2rem" }}>
            <Grid item xs={5}>
              {cancelButton}
            </Grid>
            <Grid item textAlign="right" xs={7}>
              {altButton}
              {confirmButton}
            </Grid>
          </Grid>
        ) : null}
      </Stack>
    </SharedModal>
  );
};

export default ButtonModal;
