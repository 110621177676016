import { Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";

import { COLORS } from "../../colors";

const CardItem = ({ linkTo, isArchived, isPending, children, isInline = false }) => {
  let opacity;
  if (isArchived) {
    opacity = 0.5;
  } else {
    opacity = 1;
  }

  const defaultStyling = {
    background: isPending ? COLORS.veryLightOrange : COLORS.white,
    height: "100%",
    padding: "0.75rem",
    borderTop: `4px solid ${isArchived ? COLORS.lightOrange : COLORS.orange}`,
    opacity,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  };

  const itemContent = (
    <Box
      sx={{
        "& a:hover > .MuiBox-root": {
          background: isPending ? COLORS.lightOrange : COLORS.veryLightGray,
        },
      }}
    >
      <Link style={{ textDecoration: "none" }} to={linkTo}>
        <Box sx={defaultStyling}>{children}</Box>
      </Link>
    </Box>
  );

  return isInline ? (
    itemContent
  ) : (
    <Grid item md={4} xl={3} xs={6}>
      {itemContent}
    </Grid>
  );
};

export default CardItem;
