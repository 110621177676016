import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { countAndNoun } from "../../helpers";
import InfoBox from "../shared/InfoBox";

import AudienceDemographics from "./AudienceDemographics";

const PreviewAudience = ({
  audience,
  isStale,
  handleUpdatePreview,
  handleResetFiltersToPreview,
}) => {
  if (!audience) {
    return null;
  }
  if (!audience.metadata) {
    return "...";
  }

  let content;
  if (audience.metadata.size === 0) {
    content = <InfoBox type="error">{audience.errorInfoUserFacing}</InfoBox>;
  } else {
    content = (
      <>
        {/* @todo-ui-later improve this UI? */}
        {isStale && handleUpdatePreview && handleResetFiltersToPreview ? (
          <InfoBox type="warning">
            This preview hasn't been updated since you last modified the filters.{" "}
            <Link onClick={handleUpdatePreview}>Update your preview</Link> to see up-to-date
            information based on your current filters, or{" "}
            <Link onClick={handleResetFiltersToPreview}>reset your filters</Link> to match the
            current preview.
          </InfoBox>
        ) : null}

        <InfoBox>
          <Typography>
            <strong>{countAndNoun(audience.metadata.size, "person")}</strong>
          </Typography>
          <Typography>{countAndNoun(audience.metadata.num_phones, "phone")}</Typography>
          <Typography>{countAndNoun(audience.metadata.num_emails, "email")}</Typography>
          <AudienceDemographics audience={audience} />
        </InfoBox>
      </>
    );
  }

  return (
    <>
      <Typography variant="h3">Preview</Typography>
      {content}
    </>
  );
};

export default PreviewAudience;
