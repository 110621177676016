import AddIcon from "@mui/icons-material/Add";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Grid, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const OutreachCampaignsHeader = ({
  showNewOutreachCampaignButton = true,
  headerText = "Content evaluations",
  alternateRightContent,
  backToText = "Back to library",
  backToLink = "/evaluations",
}) => {
  const navigate = useNavigate();

  let rightContent = (
    <Grid item>
      <Button color="secondary" component={Link} startIcon={<ArrowLeftIcon />} to={backToLink}>
        {backToText}
      </Button>
    </Grid>
  );

  if (alternateRightContent !== undefined) {
    rightContent = alternateRightContent;
  }

  return (
    <Grid container alignItems="center" spacing={1.5} sx={{ marginBottom: "30px" }}>
      <Grid item>
        <Typography sx={{ margin: 0, paddingRight: "1rem" }} variant="h2">
          {headerText}
        </Typography>
      </Grid>
      {showNewOutreachCampaignButton ? (
        <Grid item>
          <Button
            color="secondary"
            onClick={() => navigate("/evaluations/new")}
            startIcon={<AddIcon />}
          >
            New
          </Button>
        </Grid>
      ) : null}
      <Grid item xs></Grid>
      {rightContent}
    </Grid>
  );
};

export default OutreachCampaignsHeader;
