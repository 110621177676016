import { reverse, sortBy } from "lodash";

import { CONTENT_GROUP_TYPES } from "../constants";

import useGetActiveOrg from "./useGetActiveOrg";

// Sort recent first by default for this one
const useGetCurrentContentGroups = () => {
  const { organization } = useGetActiveOrg();
  const { contentGroups } = organization;
  // @todo-content add some filters here.

  const filteredContentGroups = contentGroups.filter(cg =>
    Object.keys(CONTENT_GROUP_TYPES).includes(cg.type),
  );
  return reverse(sortBy(filteredContentGroups, "createdAt"));
};

export default useGetCurrentContentGroups;
