import dayjs from "dayjs";
import { pick, forEach } from "lodash";
import pluralize from "pluralize";
import { useMemo } from "react";

import { useAppState } from "../AppStateContext";
import { GEO_TYPES, HAS_DONOR_HISTORY } from "../constants";
import { countAndNoun, userFriendlyNumber } from "../helpers";
import useGetGeoFilterOptions from "../hooks/useGetGeoFilterOptions";

const useAudienceFiltersSummaries = filters => {
  const { constants } = useAppState();
  const geoFilterOptions = useGetGeoFilterOptions();

  const filtersSummaries = useMemo(() => {
    const getDisplayStringForGeoValue = (value, geoType) => {
      if (geoType === "states") {
        return constants.US_STATES_AND_DC.find(state => state.value === value).label;
      } else {
        // Loading geo filter options, use value as a placeholder (we're going to show a loading
        // UI anyway).
        if (!geoFilterOptions) {
          return value;
        }
        return geoFilterOptions[geoType].find(option => option.value === value).label;
      }
    };

    if (!filters) {
      return [];
    }

    let summaries = {};
    const geographyFilters = pick(filters, Object.keys(GEO_TYPES));
    forEach(geographyFilters, (values, geoType) => {
      if (!values) {
        return null;
      }
      const geoTypeDisplay = pluralize(GEO_TYPES[geoType], values.length);
      const geoFiltersForTypeDisplay = values
        .map(v => getDisplayStringForGeoValue(v, geoType))
        .join(", ");
      summaries[geoType] = `${geoTypeDisplay}: ${geoFiltersForTypeDisplay}`;
    });

    if (filters.ageStart || filters.ageEnd) {
      summaries["age"] = `Age range: ${filters.ageStart || constants.AGE_MIN}-${
        filters.ageEnd || constants.AGE_MAX
      } ${filters.ageIncludeUnknown ? "(including unknown ages)" : ""}`;
    }

    if (filters.registrationDateStart || filters.registrationDateEnd) {
      const registrationDateRangeDisplay = `${
        filters.registrationDateStart ? dayjs(filters.registrationDateStart).year() : "Any"
      }-${filters.registrationDateEnd ? dayjs(filters.registrationDateEnd).year() : "Any"}`;
      summaries[
        "registrationDateRange"
      ] = `Registration date range: ${registrationDateRangeDisplay}`;
    }

    if (filters.genders) {
      const genderFiltersDisplay = constants.GENDERS.filter(g => filters.genders.includes(g.value))
        .map(g => g.label)
        .join(", ");
      summaries["genders"] = `${pluralize("Sex", filters.genders.length)}: ${genderFiltersDisplay}`;
    }

    if (filters.races) {
      const raceFiltersDisplay = constants.RACES.filter(r => filters.races.includes(r.value))
        .map(r => r.label)
        .join(", ");
      summaries["races"] = `${pluralize("Race", filters.races.length)}: ${raceFiltersDisplay}`;
    }

    if (filters.hasDonorHistory !== null) {
      const hasDonorHistoryDisplay = HAS_DONOR_HISTORY.find(
        o => o.value === filters.hasDonorHistory.toString(),
      ).label;
      summaries["hasDonorHistory"] = `Donation history: ${hasDonorHistoryDisplay}`;
    }

    if (filters.contactInfo) {
      const contactInfoDisplay = constants.CONTACT_INFO.find(
        c => c.value === filters.contactInfo,
      ).label;
      summaries["contactInfo"] = `Contact info: ${contactInfoDisplay}`;
    }

    if (filters.sortField) {
      const sortDisplay = constants.SORT_FIELDS.find(sf => sf.value === filters.sortField).label;
      let limitDisplay = "";
      if (filters.limit) {
        limitDisplay = `, Limit: ${userFriendlyNumber(filters.limit)}`;
      }
      summaries["sortAndLimit"] = `Sort by: ${sortDisplay}${limitDisplay}`;
    }

    if (filters.zip && filters.distanceFromZip !== null) {
      const zipDisplay = `${countAndNoun(filters.distanceFromZip, "mile")} from ${filters.zip}`;
      summaries["distanceFromZip"] = zipDisplay;
    }

    return summaries;
  }, [filters, constants, geoFilterOptions]);

  return {
    filtersSummaries,
    filtersSummariesList: Object.values(filtersSummaries),
    isFiltersSummariesLoading: !geoFilterOptions,
  };
};

export default useAudienceFiltersSummaries;
