import { reverse, sortBy } from "lodash";

import useGetActiveOrg from "./useGetActiveOrg";

const useGetCurrentAudiences = ({
  includeArchived = false,
  includePending = false,
  sortRecentFirst = false,
} = {}) => {
  const { organization } = useGetActiveOrg();
  const { audiences } = organization;

  let statusesToInclude = ["ACTIVE"];
  if (includePending) {
    statusesToInclude.push("PENDING");
  }

  const filteredAudiences = audiences.filter(
    a =>
      a.isCurrentVersion &&
      (includeArchived ? true : !a.isArchived) &&
      !a.isPreview &&
      statusesToInclude.includes(a.status),
  );

  return sortRecentFirst ? reverse(sortBy(filteredAudiences, "createdAt")) : filteredAudiences;
};

export default useGetCurrentAudiences;
