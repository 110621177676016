import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import { COLORS } from "../../colors";

const FILE_TYPES = {
  image: {
    // See the `Extensions` enum in content_component_image.py
    accept: {
      "image/png": [".png"],
      "image/gif": [".gif"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    maxSizeMbs: 50,
    rejectedMessage: "Only image files (.png, .gif, .jpg, .jpeg) 50MB or smaller are accepted.",
    showRejectedMessageInDropzone: true,
  },
  CSV: {
    rejectedMessage: "Only CSV files are valid for matching.",
    accept: {
      "application/vnd.ms-excel": [".csv"],
      "text/*": [".csv"],
    },
  },
  PDF: {
    rejectedMessage: "Only PDF files are valid for direct mail content.",
    accept: {
      "application/pdf": [".pdf"],
    },
  },
};

// Styling based on example here: https://react-dropzone.js.org/#section-styling-dropzone
const getBackgroundColor = ({ isFocused, isDragAccept, isDragReject }) => {
  if (isDragAccept) {
    return COLORS.green;
  }
  if (isDragReject) {
    return COLORS.red;
  }
  if (isFocused) {
    return COLORS.purple;
  }
  return COLORS.veryDarkGray;
};

const FileDropzone = ({ onSelect, fileType }) => {
  const fileTypeInfo = FILE_TYPES[fileType];
  const maxSizeMbs = fileTypeInfo.maxSizeMbs;
  const maxSizeBytes = maxSizeMbs && maxSizeMbs * 1024 * 1024;
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: fileTypeInfo.accept,
    maxFiles: 1,
    maxSize: maxSizeBytes,
    onDropAccepted: files => {
      onSelect(files[0]);
    },
    onDropRejected: fileRejections => {
      toast(fileTypeInfo.rejectedMessage, { type: "error" });
    },
    useFsAccessApi: false, // need this for playwright compatibility
  });

  const style = {
    backgroundColor: COLORS.lighterGray,
    borderColor: getBackgroundColor({ isFocused, isDragAccept, isDragReject }),
    borderRadius: "2px",
    borderStyle: "dashed",
    borderWidth: "2px",
    color: COLORS.darkerGray,
    cursor: "pointer",
    fontSize: "1.5em",
    fontWeight: "bold",
    height: "100px",
    outline: "none",
    transition: "border 0.24s ease-in-out",
  };

  return (
    <div className="container">
      <Stack
        {...getRootProps({ style })}
        alignItems="center"
        justifyContent="center"
        spacing={-1}
        width={460}
      >
        <input {...getInputProps()} />
        <div>
          <UploadFileIcon />
        </div>
        <div>Drop in {fileType} or click to select</div>
        {fileTypeInfo.showRejectedMessageInDropzone ? (
          <Typography sx={{ paddingTop: "7px" }} variant="subtitle2">
            {fileTypeInfo.rejectedMessage}
          </Typography>
        ) : null}
      </Stack>
    </div>
  );
};

export default FileDropzone;
