import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { COLORS } from "../colors";
import { countAndNoun } from "../helpers";
import useGetCurrentAudiences from "../hooks/useGetCurrentAudiences";
import useGetCurrentOutreachCampaigns from "../hooks/useGetCurrentOutreachCampaigns";
import { TypographyWithIcon } from "../styles";

import CheckboxIcon from "./svgs/icons/CheckboxIcon";
import LightningIcon from "./svgs/icons/LightningIcon";
import MegaphoneIcon from "./svgs/icons/MegaphoneIcon";
import SmileIcon from "./svgs/icons/SmileIcon";
import StarIcon from "./svgs/icons/StarIcon";
import SunIcon from "./svgs/icons/SunIcon";

const DashboardHeader = ({ color, children }) => {
  return (
    <Grid item marginTop="1rem" xs={12}>
      <Typography backgroundColor={color} marginBottom="0.25rem" variant="h1">
        {children}
      </Typography>
    </Grid>
  );
};

const LibraryLinkWrapper = ({ children }) => (
  <Grid item sx={{ marginBottom: "0.5rem" }} xs={12}>
    <Typography sx={{ background: COLORS.lightGray, textAlign: "center", padding: "0.75rem" }}>
      {children}
    </Typography>
  </Grid>
);

const ActionBox = ({ borderColor, to, children }) => (
  <Grid item xs={6}>
    <Box
      sx={{
        height: "100%",
        "& a:hover > .MuiBox-root": {
          background: COLORS.veryLightGray,
        },
      }}
    >
      <Link style={{ textDecoration: "none" }} to={to}>
        <Box
          sx={{
            background: COLORS.white,
            borderTop: `4px solid ${borderColor}`,
            padding: "1rem",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            height: "100%",
          }}
        >
          {children}
        </Box>
      </Link>
    </Box>
  </Grid>
);

const Dashboard = () => {
  const numCurrentAudiences = useGetCurrentAudiences().length;
  const numCurrentContentEvaluations = useGetCurrentOutreachCampaigns().length;

  const audienceLibraryLinkBox =
    numCurrentAudiences > 0 ? (
      <LibraryLinkWrapper>
        <Link to="/audiences">
          Explore your {countAndNoun(numCurrentAudiences, "existing audience")} &rarr;
        </Link>
      </LibraryLinkWrapper>
    ) : null;

  const contentEvaluationLibraryLinkBox =
    numCurrentContentEvaluations > 0 ? (
      <LibraryLinkWrapper>
        <Link to="/evaluations">
          Explore your {countAndNoun(numCurrentContentEvaluations, "prior content evaluation")}{" "}
          &rarr;
        </Link>
      </LibraryLinkWrapper>
    ) : null;

  return (
    <Grid container spacing={3}>
      <DashboardHeader color={COLORS.blue}>Audiences</DashboardHeader>

      {audienceLibraryLinkBox}

      <ActionBox borderColor={COLORS.purple} to="/audiences/create">
        <TypographyWithIcon variant="h3">
          <SmileIcon /> Create a filtered audience
        </TypographyWithIcon>
        <Typography>
          Our list-building tool lets you filter the voter file to create an audience that you can
          then export or use elsewhere in Matchbook.
        </Typography>
      </ActionBox>

      <ActionBox borderColor={COLORS.purple} to="/audiences/enrich">
        <TypographyWithIcon variant="h3">
          <CheckboxIcon /> Match and enrich a CSV
        </TypographyWithIcon>
        <Typography>
          Upload a list of voters and use whatever info you have &mdash; names, emails, phones, etc.
          &mdash; to match it to our national voter file.
        </Typography>
      </ActionBox>

      <ActionBox borderColor={COLORS.purple} to="/audiences/expand">
        <TypographyWithIcon variant="h3">
          <SunIcon /> Expand a matched audience
        </TypographyWithIcon>
        <Typography>
          Looking for new fundraising prospects? Use our lookalike models and your existing matched
          audiences to find new people with similar traits.
        </Typography>
      </ActionBox>

      <ActionBox borderColor={COLORS.purple} to="/audiences/predict">
        <TypographyWithIcon variant="h3">
          <LightningIcon /> Deploy a predictive model (coming soon)
        </TypographyWithIcon>
        <Typography>
          Upload survey data and automatically generate a predictive model, which can then be used
          to score the national voter file.
        </Typography>
      </ActionBox>

      <DashboardHeader color={COLORS.teal}>Content</DashboardHeader>

      {contentEvaluationLibraryLinkBox}

      <ActionBox borderColor={COLORS.teal} to="/evaluations/new">
        <TypographyWithIcon variant="h3">
          <StarIcon /> Estimate content effectiveness
        </TypographyWithIcon>
        <Typography>
          Get an instant estimate of how much money a digital ad might bring in from a specific
          audience.
        </Typography>
      </ActionBox>

      <ActionBox borderColor={COLORS.teal} to="/content/generate">
        <TypographyWithIcon variant="h3">
          <MegaphoneIcon /> Generate new content (coming soon)
        </TypographyWithIcon>
        <Typography>
          Use your historical texts or emails, and our in-house models, to generate new draft
          content optimized for a specific audience.
        </Typography>
      </ActionBox>
    </Grid>
  );
};

export default Dashboard;
