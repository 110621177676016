import { SvgIcon } from "@mui/material";

const StarIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m 13.079846,3.8200615 1.345892,4.3273823 c 0.152313,0.4888564 0.588485,0.820072 1.080032,0.820072 h 4.356129 c 1.100781,0 1.557817,1.4708862 0.667472,2.1463212 l -3.523989,2.674242 c -0.398781,0.302278 -0.564938,0.837428 -0.412625,1.326267 l 1.345876,4.327371 c 0.340641,1.093482 -0.857111,2.001668 -1.747456,1.326337 L 12.667221,18.09376 c -0.398781,-0.302277 -0.937407,-0.302277 -1.334797,0 L 7.8084186,20.768054 C 6.91809,21.443385 5.7203708,20.535199 6.060996,19.441717 L 7.4068879,15.114346 C 7.5592006,14.625507 7.3930443,14.090357 6.9942627,13.788079 L 3.4702969,11.113837 C 2.5799602,10.438402 3.0382923,8.9675158 4.1377152,8.9675158 h 4.356143 c 0.4915633,0 0.927703,-0.3312156 1.0800157,-0.820072 L 10.919799,3.8200615 c 0.340625,-1.0934155 1.820797,-1.0934155 2.160047,0 z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default StarIcon;
