import { useCallback, useEffect, useMemo, useState } from "react";

import LoadingExtended from "./LoadingExtended";

const INITIAL_COMPLETED_STEP = -1;

const LoadingExtendedFaux = ({ isLoading, stepDurationSeconds = 5, steps, ...rest }) => {
  const [completedStep, setCompletedStep] = useState(INITIAL_COMPLETED_STEP);
  const [shouldAutomaticallyIncrementSteps, setShouldAutomaticallyIncrementSteps] = useState(false);
  const stepDurationMs = useMemo(() => stepDurationSeconds * 1000, [stepDurationSeconds]);

  // Kick things off when isLoading is set to true. This makes it so that the parent component
  // can cycle isLoading from false to true multiple times.
  useEffect(() => {
    if (isLoading && !shouldAutomaticallyIncrementSteps) {
      setShouldAutomaticallyIncrementSteps(true);
      setCompletedStep(INITIAL_COMPLETED_STEP);
    }
  }, [shouldAutomaticallyIncrementSteps, isLoading]);

  // When isLoading turns false and we're still in the process of automatically incrementing steps.
  // 1. Check off the last step (always) as well as any previous ones remaining (if relevant).
  // 2. Reset `shouldAutomaticallyIncrementSteps` to false.
  useEffect(() => {
    if (!isLoading && shouldAutomaticallyIncrementSteps && completedStep < steps.length - 1) {
      setCompletedStep(steps.length - 1);
      setShouldAutomaticallyIncrementSteps(false);
    }
  }, [isLoading, shouldAutomaticallyIncrementSteps, completedStep, setCompletedStep, steps.length]);

  // Don't check off the final step from here since isLoading might still be true. The useEffect
  // above will handle the final step when isLoading changes to false.
  const incrementStep = useCallback(() => {
    setCompletedStep(prevStep => (prevStep < steps.length - 2 ? prevStep + 1 : prevStep));
  }, [steps.length, setCompletedStep]);

  // Automatically check off the next step periodically when appropriate.
  useEffect(() => {
    if (shouldAutomaticallyIncrementSteps && isLoading) {
      setTimeout(incrementStep, stepDurationMs);
    }
  }, [incrementStep, completedStep, isLoading, shouldAutomaticallyIncrementSteps, stepDurationMs]);

  return <LoadingExtended completedStep={completedStep} steps={steps} {...rest} />;
};

export default LoadingExtendedFaux;
