import { Autocomplete, TextField, Typography } from "@mui/material";

import TextLabel from "../shared/TextLabel";

// @todo consider refactoring this to base it on DropdownSelectOne (just for consistency). this
// would require defining the options prop the way that other component does: just a list of
// value/label tuples
const SelectOrgRelatedObject = ({
  getOptionLabel,
  label,
  options,
  selectedObject,
  setSelectedObject,
  isDisabled = false,
  placeholder = "",
  subtitle = null,
  width = null,
}) => {
  return (
    <>
      {label ? <TextLabel>{label}:</TextLabel> : null}
      {subtitle ? (
        <Typography gutterBottom variant="subtitle2">
          {subtitle}
        </Typography>
      ) : null}
      <Autocomplete
        disableClearable
        disabled={isDisabled}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) =>
          value !== null &&
          value !== undefined &&
          (option.typedId ? option.typedId === value.typedId : option.id === value.id)
        }
        onChange={(e, selectedOption) => setSelectedObject(selectedOption)}
        options={options}
        renderInput={params => <TextField {...params} placeholder={placeholder} size="small" />}
        renderOption={(props, object) => (
          <li {...props} key={object.typedId || object.id}>
            {getOptionLabel(object)}
          </li>
        )}
        sx={{ margin: 0, width }}
        value={selectedObject === undefined ? null : selectedObject}
      />
    </>
  );
};

export default SelectOrgRelatedObject;
