import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";

import SelectAudience from "./SelectAudience";

const SelectNationalVoterFileOrExistingAudience = ({
  isDisabled,
  selectedAudience,
  setSelectedAudienceAndIsStartFromExisting,
  isStartFromExisting,
  nationalVoterFileLabel = "National voter file",
}) => {
  const isNationalVoterFileSelected = !isStartFromExisting && !selectedAudience;
  return (
    <RadioGroup
      aria-labelledby="apply-filters-to"
      defaultValue="true"
      onChange={e =>
        setSelectedAudienceAndIsStartFromExisting({
          newSelectedAudience: null,
          newIsStartFromExisting: e.target.value !== "true",
        })
      }
      value={isNationalVoterFileSelected}
    >
      <FormControlLabel
        control={<Radio />}
        disabled={isDisabled}
        label={nationalVoterFileLabel}
        name="start-from-national-voter-file"
        value="true"
      />
      <FormControlLabel
        disableTypography
        control={<Radio />}
        disabled={isDisabled}
        label={
          <Grid container alignItems="center" spacing={1}>
            <Grid item>Existing audience: </Grid>
            <Grid item xs>
              <SelectAudience
                disableIfNoAudiences={true}
                isDisabled={isDisabled}
                label={null}
                placeholder={!isNationalVoterFileSelected ? "Select an audience" : null}
                selectedAudience={
                  selectedAudience && selectedAudience.isArchived ? null : selectedAudience
                }
                setSelectedAudience={newSelectedAudience =>
                  setSelectedAudienceAndIsStartFromExisting({ newSelectedAudience })
                }
              />
            </Grid>
          </Grid>
        }
        name="start-from-existing-audience"
        value="false"
      />
    </RadioGroup>
  );
};

export default SelectNationalVoterFileOrExistingAudience;
