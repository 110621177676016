import { Button } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

import CsvMatchSummaryModal from "./CsvMatchSummaryModal";

const InlineCsvUploadInfo = ({ audience, csv, errorMsg, participle }) => {
  const [isCsvMatchSummaryModalOpen, setIsCsvMatchSummaryModalOpen] = useState(false);

  // The metadata *should* always be present for any audiences generated after #622 is merged, but
  // let's not provide a broken link if the metadata is missing for some reason.
  const metadataSection = audience.uploadedAudienceCsvMatchMetadata ? (
    <>
      <br />
      <br />
      <Button color="secondary" onClick={() => setIsCsvMatchSummaryModalOpen(true)} size="small">
        Review matching details
      </Button>
    </>
  ) : null;

  const copy = errorMsg ? (
    <>
      {errorMsg}
      {metadataSection}
    </>
  ) : (
    <>
      You're {participle} the audience you just uploaded.
      {metadataSection}
      <br />
      <br />
      You can also <Link to={`/audiences/${audience.id}`}>access this audience</Link> later in your
      audience library.
    </>
  );

  return (
    <>
      {copy}
      <CsvMatchSummaryModal
        {...{ audience, csv }}
        isOpen={isCsvMatchSummaryModalOpen}
        setIsOpen={setIsCsvMatchSummaryModalOpen}
      />
    </>
  );
};

export default InlineCsvUploadInfo;
