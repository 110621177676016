import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { CONTENT_GROUP_TYPES } from "../../constants";
import { getInitialContentVariationData, validateContentVariationData } from "../../helpers";
import useCreateContentVariation from "../../hooks/useCreateContentVariation";
import useFormErrors from "../../hooks/useFormErrors";
import useGetContentGroupFromParam from "../../hooks/useGetContentGroupFromParam";
import useGetContentVariationFromParam from "../../hooks/useGetContentVariationFromParam";
import Loading from "../shared/Loading";
import TwoColumnLayoutWithPreview from "../shared/TwoColumnLayoutWithPreview";

import ContentGroupsHeader from "./ContentGroupsHeader";
import CreateContentVariationForm from "./CreateContentVariationForm";
import VariationPreviewForForm from "./VariationPreviewForForm";

const getNextVariationNumber = variations => {
  const numbers = variations
    .map(variation => {
      const match = variation.name.match(/#(\d+)/);
      return match ? parseInt(match[1], 10) : 0;
    })
    // Include 0 in case there are no existing variations.
    .concat(0);

  return Math.max(...numbers) + 1;
};

const CreateContentVariation = ({
  contentGroup: contentGroupProp,
  isInline = false,
  handleContentWasCreated,
}) => {
  const contentGroupFromParam = useGetContentGroupFromParam();
  // Note that contentGroupProp has to be first here, because the "create new evaluation"
  // page can have a content group selected that would fulfill the param but not be the new
  // one that the user is currently creating in the modal!
  const contentGroup = contentGroupProp || contentGroupFromParam;

  const existingVariations = useMemo(() => contentGroup.variations, [contentGroup]);
  const nextVariationNumber = useMemo(
    () => getNextVariationNumber(contentGroup.variations),
    [contentGroup],
  );
  const defaultVariationData = useMemo(
    () => getInitialContentVariationData(nextVariationNumber),
    [nextVariationNumber],
  );
  const [contentVariationData, setContentVariationData] = useState(defaultVariationData);
  const { variation: selectedExistingVariation } = useGetContentVariationFromParam();

  const { formErrors, validateFieldIsPresent } = useFormErrors();
  const navigate = useNavigate();
  const { createContentVariation, createContentVariationIsLoading } = useCreateContentVariation(
    contentGroup.type,
  );

  const onSelectExistingVariation = useCallback(
    variation => {
      if (variation) {
        setContentVariationData({
          name: defaultVariationData.name,
          textContent: variation.text.content,
          contentComponentImageId: variation.image && variation.image.id,
          previewAttachmentUrl: variation.image && variation.image.url,
        });
      } else {
        setContentVariationData(defaultVariationData);
      }
    },
    [defaultVariationData],
  );

  const selectExistingVariation = variation => {
    if (isInline) {
      onSelectExistingVariation(variation);
    } else {
      navigate(
        `/content/variation/new/${contentGroup.typedId}/${variation ? variation.typedId : ""}`,
      );
    }
  };

  const contentGroupPath = `/content/group/${contentGroup.typedId}`;

  useEffect(() => {
    if (contentGroup.type === "DirectMail" && selectedExistingVariation) {
      toast("A new direct mail variation can't be based on an existing one.", { type: "error" });
      navigate(contentGroupPath);
    } else {
      onSelectExistingVariation(selectedExistingVariation);
    }
  }, [
    selectedExistingVariation,
    onSelectExistingVariation,
    contentGroup.type,
    contentGroupPath,
    navigate,
  ]);

  const onCreate = () => {
    toast("Your variation was successfully created.", { type: "success" });
    handleContentWasCreated && handleContentWasCreated(contentGroup);
    if (!isInline) {
      navigate(contentGroupPath);
    }
  };

  const validateFields = () =>
    validateContentVariationData({
      contentVariationData,
      type: contentGroup.type,
      validateFieldIsPresent,
    });

  const doCreateContentVariation = () => {
    if (!validateFields()) {
      return;
    }
    createContentVariation({
      contentVariationData,
      contentGroupRawId: contentGroup.id,
      onCreate,
    });
  };

  const getVariationLabel = variation => variation.name;

  if (createContentVariationIsLoading) {
    return <Loading />;
  }

  const mainContent = (
    <>
      {existingVariations.length > 0 && contentGroup.type !== "DirectMail" ? (
        <>
          <Typography variant="h4">
            Select an existing variation to start from (optional)
          </Typography>
          {/* @todo-aviv because of how the selected existing variation is currently stored
          (in the URL), we don't show the selected variation once it's selected for the inline
          version. This isn't necessarily a huge deal but we should probably fix it. */}
          <Autocomplete
            getOptionLabel={getVariationLabel}
            onChange={(e, selectedOption) => selectExistingVariation(selectedOption)}
            options={existingVariations}
            renderInput={params => <TextField {...params} placeholder="" size="small" />}
            sx={{ margin: "1rem 0" }}
            value={selectedExistingVariation || null}
          />
        </>
      ) : null}
      <CreateContentVariationForm
        {...{
          formErrors,
          validateFieldIsPresent,
          setContentVariationData,
          contentVariationData,
        }}
        isFirstVariation={false}
        type={contentGroup.type}
      />
      <Button onClick={doCreateContentVariation}>Create variation</Button>
    </>
  );

  const previewContent = (
    <VariationPreviewForForm type={contentGroup.type} variationData={contentVariationData} />
  );

  const typeName = CONTENT_GROUP_TYPES[contentGroup.type].name.toLowerCase();

  return (
    <>
      {isInline ? (
        mainContent
      ) : (
        <>
          <ContentGroupsHeader
            backToLink={`/content/group/${contentGroup.typedId}`}
            backToText="Back to content"
            headerText={`Add new ${typeName} variation`}
            showNewContentGroupButton={false}
          />
          <TwoColumnLayoutWithPreview mainContent={mainContent} previewContent={previewContent} />
        </>
      )}
    </>
  );
};

export default CreateContentVariation;
