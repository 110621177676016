import { gql, useLazyQuery, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { isEqual, uniq } from "lodash";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { useAppState, useAppStateDispatch } from "../../AppStateContext";
import {
  CORE_AUDIENCE_FIELDS,
  CORE_UPLOADED_AUDIENCE_CSV_FIELDS,
  GEO_FILTER_OPTION_FIELDS,
} from "../../fragments";
import useGetActiveOrg from "../../hooks/useGetActiveOrg";
import Loading from "../shared/Loading";

export const GET_AUDIENCES_AND_CSVS_FOR_ORG = gql`
  ${CORE_UPLOADED_AUDIENCE_CSV_FIELDS}
  ${CORE_AUDIENCE_FIELDS}
  query getAudiencesAndCsvs($organizationId: ID!) {
    organization(pk: $organizationId) {
      id
      audiences {
        ...CoreAudienceFields
      }
      uploadedAudienceCsvs {
        ...CoreUploadedAudienceCsvFields
      }
    }
  }
`;

const POLL_FOR_PENDING_AUDIENCES = gql`
  query pollForPendingAudiences($audienceIds: [ID!]!) {
    audiences(audienceIds: $audienceIds) {
      id
      isArchived
      status
      uploadedAudienceCsv {
        id
        status
      }
      facebookCustomAudienceExports {
        id
        createdAt
        status
      }
    }
  }
`;

export const GET_GEO_FILTER_OPTIONS = gql`
  ${GEO_FILTER_OPTION_FIELDS}
  query getGeoFilterOptions {
    geoFilterOptions {
      counties {
        ...GeoFilterOptionFields
      }
      congressionalDistricts {
        ...GeoFilterOptionFields
      }
      stateLegLowerDistricts {
        ...GeoFilterOptionFields
      }
      stateLegUpperDistricts {
        ...GeoFilterOptionFields
      }
    }
  }
`;

const POLL_INTERVAL = 5000;

// If we're waiting on an async process for an audience or FB audience export and it's still pending
// or in progress for longer than this, we give up on polling for it because something has clearly
// gone wrong.
// (Note that we also mark old PENDING audiences as ERROR periodically on the backend.)
const MAX_ELAPSED_MINUTES_TO_POLL_FOR = 30;
const objectIsStale = object =>
  dayjs().diff(dayjs(object.createdAt), "minute") > MAX_ELAPSED_MINUTES_TO_POLL_FOR;

const AudienceData = () => {
  const dispatch = useAppStateDispatch();
  const { geoFilterOptions, additionalAudienceIdsToPollQueue } = useAppState();
  const { organizationId, organization } = useGetActiveOrg();
  const { audiences, uploadedAudienceCsvs } = organization;
  const [audienceIdsToPollFor, setAudienceIdsToPollFor] = useState([]);

  // When we have one or more pending audiences, we need to poll the server for updates.
  // - Special case that we may eliminate: when the user first uploads a CSV, we're waiting to
  //   get the list of column headers so that the user can select columns.
  // - Normal case: the user has triggered an audience creation process that's running in an async
  //   job.
  //
  // Note that we can't just use onCompleted for this query or the other query below because
  // onCompleted only gets called the first time we issue a query (not on subsequent polling or
  // refetching). So we use effects instead. :/
  // See: https://github.com/apollographql/apollo-client/issues/5531
  //
  // Also note that we may end up replacing this polling with websockets.
  const [
    startInitialPolling,
    {
      data: pollData,
      called: initialPollingStarted,
      refetch: refetchPolling,
      startPolling: resumePolling,
      stopPolling: disablePolling,
    },
  ] = useLazyQuery(POLL_FOR_PENDING_AUDIENCES, {
    pollInterval: POLL_INTERVAL,
  });

  // This is the main query that fetches all of the org's audience data.
  const { data: audienceData, refetch } = useQuery(GET_AUDIENCES_AND_CSVS_FOR_ORG, {
    variables: { organizationId },
  });

  // This just stores the data from the main query. As noted above, we can't use onCompleted for
  // this because, annoyingly, that won't be called after refetches.
  useEffect(() => {
    if (audienceData) {
      dispatch({ type: "org-set-audiences-and-csvs", organization: audienceData.organization });
    }
  }, [audienceData, dispatch]);

  // When we add an audience to `audienceIdsToPollFor` from `additionalAudienceIdsToPollQueue`
  // (in app state), remove it from the queue.
  useEffect(() => {
    if (!additionalAudienceIdsToPollQueue.length) {
      return;
    }
    const audiencesPolledFromQueue = additionalAudienceIdsToPollQueue.filter(a =>
      audienceIdsToPollFor.includes(a),
    );
    if (audiencesPolledFromQueue) {
      dispatch({
        type: "additional-audiences-to-poll-queue-remove",
        audienceIdsToRemove: audiencesPolledFromQueue,
      });
    }
  }, [additionalAudienceIdsToPollQueue, audienceIdsToPollFor, dispatch]);

  // Update our polling status depending on whether there are any relevant pending audiences.
  useEffect(() => {
    if (!audiences) {
      return;
    }

    // We should poll for any non-archived pending audience except those that have a CSV with status
    // READY_FOR_COLUMN_SELECTION (since those are waiting on the user's input).
    const newAudienceIdsToPollFor = uniq(
      audiences
        .filter(audience => {
          // Facebook custom audience exports can be slow. Ideally we would just poll for that
          // specific record instead of the whole audience, but it's a trivial difference.
          if (
            audience.facebookCustomAudienceExports &&
            audience.facebookCustomAudienceExports.find(
              fca => fca.status === "IN_PROGRESS" && !objectIsStale(fca),
            )
          ) {
            return true;
          }

          if (objectIsStale(audience)) {
            return false;
          }

          // If it's an audience created by filtering (i.e. has filters and isn't a lookalike),
          // we shouldn't need to poll for it because it was created synchronously. We're adding
          // this specific condition to avoid inadvertently polling for these kinds of audiences
          // stuck in a PENDING state due to e.g. a memory error (as we saw during testing).
          if (!!audience.filters && !audience.audienceLookalikeCreatedFrom) {
            return false;
          }
          if (audience.status !== "PENDING" || audience.isArchived) {
            return false;
          }

          const csv = audience.uploadedAudienceCsv
            ? uploadedAudienceCsvs.find(csv => csv.id === audience.uploadedAudienceCsv.id)
            : null;
          return !(csv && csv.status === "READY_FOR_COLUMN_SELECTION");
        })
        .map(a => a.id)
        .concat(additionalAudienceIdsToPollQueue),
    ).sort();

    if (isEqual(newAudienceIdsToPollFor, audienceIdsToPollFor)) {
      return;
    }

    setAudienceIdsToPollFor(newAudienceIdsToPollFor);

    if (newAudienceIdsToPollFor.length) {
      const variables = { audienceIds: newAudienceIdsToPollFor };
      if (initialPollingStarted) {
        // If the list of audiences that we need to poll for has changed since we last issued
        // this query, we need to force the query to poll with the updated variables. The only way
        // to do this is to explicitly stop polling, call refetch with the new variables, and
        // then start polling again.
        disablePolling();
        refetchPolling(variables);
        resumePolling(POLL_INTERVAL);
      } else {
        startInitialPolling({ variables });
      }
    } else {
      disablePolling();
    }
  }, [
    audiences,
    additionalAudienceIdsToPollQueue,
    audienceIdsToPollFor,
    disablePolling,
    initialPollingStarted,
    organization.isExemptFromPayments,
    refetchPolling,
    resumePolling,
    startInitialPolling,
    uploadedAudienceCsvs,
  ]);

  // This effect looks at the polling data to determine whether a full refetch of the org's
  // audience data is warranted. Specific cases that necessitate a refetch:
  // 1. An uploaded CSV's status changed. The upload flow is dependent on these updates to:
  //    - Show the column selection form when we're ready for the user to make their selections
  //      (status: `READY_FOR_COLUMN_SELECTION`).
  //    - Show "Matching in progress" When the user completes the column selection step,
  //      i.e. transitions the CSV status from `READY_FOR_COLUMN_SELECTION` to `ACTIVE`.
  //    - Handle the not strictly necessary transition from `READY_FOR_UPLOAD` to
  //      `READY_FOR_PARSING` (the only consequence is that we show a different loading message).
  // 2. An asynchronously generated audience just transitioned from `PENDING` to something else.
  // 3. The status of an `IN PROGRESS` Facebook custom audience export changed.
  useEffect(() => {
    if (!pollData) {
      return;
    }

    // shouldRefetch indicates whether we should kick off another full query of the org's audience
    // data. We do this because the no-longer-pending audience may have other fields that have
    // changed but that were not requested by the polling query. Note that we choose not to dispatch
    // these to the store because we want to wait for the full refetch query to get all relevant
    // updates for these audiences.
    const shouldRefetch = pollData.audiences.some(audience => {
      const oldAudience = audiences.find(a => a.id === audience.id);

      if (!oldAudience || oldAudience.status !== audience.status) {
        return true;
      }

      if (oldAudience.uploadedAudienceCsv) {
        const oldCsv = uploadedAudienceCsvs.find(c => c.id === oldAudience.uploadedAudienceCsv.id);
        const newCsv = audience.uploadedAudienceCsv;
        if (oldCsv && newCsv && oldCsv.status !== newCsv.status) {
          return true;
        }
      }

      // This is fairly hacky. Flattening out the global store would make this simpler.
      const facebookCustomAudienceExportHasChanged = audience.facebookCustomAudienceExports.some(
        fcae => {
          const oldFcae = oldAudience.facebookCustomAudienceExports.find(f => f.id === fcae.id);
          return oldFcae && oldFcae.status !== fcae.status;
        },
      );
      if (facebookCustomAudienceExportHasChanged) {
        return true;
      }

      return false;
    });

    if (shouldRefetch) {
      refetch();
    }
  }, [pollData, refetch, audiences, dispatch, organizationId, uploadedAudienceCsvs]);

  useQuery(GET_GEO_FILTER_OPTIONS, {
    onCompleted: data =>
      dispatch({ type: "geo-filter-options-set", geoFilterOptions: data.geoFilterOptions }),
    skip: !!geoFilterOptions,
  });

  if (!audiences) {
    return <Loading />;
  }
  return <Outlet />;
};

export default AudienceData;
